import React, { useState, createContext, useContext, useEffect, useRef } from "react";

const AlertsContext = createContext();

export function AlertsProvider({ children }) {
  const alertRef = useRef(null);
  const [alert, setAlert] = useState(null);
  const [modal, setModal] = useState(null);
  const [zIndex, setZIndex] = useState(1055);

  useEffect(() => {
    const modalElement = alertRef.current;
    if (modalElement) {
      const newModal = new window.bootstrap.Modal(modalElement, { keyboard: false });
      setModal(newModal);
      modalElement.addEventListener("hidden.bs.modal", () => {
        setAlert(null);
        setZIndex(1055); // Reset z-index after closing
      });
    }
  }, []);

  const showAlert = (type, title, message, onConfirm) => {
    setAlert({ type, title, message, onConfirm });
    setTimeout(() => {
      if (modal) {
        const allModals = document.querySelectorAll(".modal.show");
        const highestZIndex = [...allModals].reduce((max, modal) => {
          const currentZIndex = parseInt(window.getComputedStyle(modal).zIndex, 10);
          return Math.max(max, currentZIndex);
        }, 1055);
        
        setZIndex(highestZIndex + 5); // Ensure the alert modal is on top
        modal._element.style.zIndex = highestZIndex + 5;
        modal.show();

        let zIndexStart = 1050;
        setTimeout(_ => {
            var modalsBackDrop = document.querySelectorAll('.modal-backdrop');
            modalsBackDrop.forEach(element => {
                element.style.zIndex = zIndexStart;
                zIndexStart = zIndexStart + 5;
            });
        }, 0);
      }
    }, 500);
  };

  const closeAlert = () => {
    if (modal) {
      modal.hide();
    }
  };

  return (
    <AlertsContext.Provider value={{ showAlert }}>
      {children}
      <div className="modal" ref={alertRef} tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true" style={{ zIndex, background: "none", boxShadow: "none" }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0 shadow-none">
            <div className={`modal-header bg-${alert?.type || "primary"} text-white border-0`}>
              <h5 className="modal-title">{alert?.title}</h5>
              <button type="button" className="btn-close bg-white" onClick={closeAlert} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {typeof alert?.message === "string" ? (
                <div dangerouslySetInnerHTML={{ __html: alert.message }} />
              ) : (
                alert?.message
              )}
            </div>
            <div className="modal-footer border-0 d-flex w-100 h-100 gap-3 justify-content-center align-items-center">
              {alert?.type === "confirm" ? (
                <>
                  <button className="btn btn-outline-dark" onClick={closeAlert}>Cancelar</button>
                  <button className="btn btn-outline-primary" onClick={() => {
                    if (alert.onConfirm) alert.onConfirm();
                    closeAlert();
                  }}>Aceptar</button>
                </>
              ) : (
                <button className="btn btn-outline-primary" onClick={closeAlert}>Cerrar</button>
              )}
            </div>
          </div>
        </div>
      </div>
    </AlertsContext.Provider>
  );
}

export function useAlerts() {
  return useContext(AlertsContext);
}
