import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import api from "../../utils/api";
import { useAlerts } from "../../components/utils/alerts";
import { showLoader, hideLoader } from "../../utils/loader";

function DashboardCustomer() {
  const { showAlert } = useAlerts();

  useEffect(() => {
    document.title = "PsiAdmin | Dashboard";
  }, []);

  const [dashboard, setDashboard] = useState({
    citasProgramadas: 0,
    pacientesRegistrados: 0,
    pacientesEnCurso: 0,
    ingresosEstimados: 0,
    chartCitasPorSemana: {
      categories: [],
      data: [],
    },
    chartIngresosPorMes: {
      categories: [],
      data: [],
    },
    patients: []
  });



  useEffect(() => {    
    getKpisCharts();
  }, [])

  /**
   * 
   * 
   * CONSUMO DE APIS
   * 
   * 
   */

  const getKpisCharts = () => {
    showLoader();
    const user = sessionStorage.getItem('user');
    let id_psychologist = JSON.parse(user).id_psychologist;

    api.post('/customer/dashboard', {
      id_psychologist: id_psychologist
    }).then(res => {
      hideLoader();

      if (typeof res.data === 'object') { 
        const response = res.data;
        setDashboard({
          citasProgramadas: response.total_citas_activas,
          pacientesRegistrados: response.total_pacientes_registrados,
          pacientesEnCurso: response.total_pacientes_en_curso,
          ingresosEstimados: response.ingresos_mes,
          chartCitasPorSemana: {
            categories: response.citas_semana.map(c => c.dia),
            data: response.citas_semana.map(c => c.total)
          },
          chartIngresosPorMes: {
            categories: response.ingresos_ytd.map(m => m.mes),
            data: response.ingresos_ytd.map(m => m.total_ventas)
          },
          patients: response.patients
        })
      }
     
      // ingresosPorMes: response.ingresos_ytd
    }).catch(err => {
      hideLoader();
      
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      setTimeout(_ => {
        showAlert('danger', 'Error', msj );
      }, 50)
    })
  }

  // Datos para los gráficos


  return (
    <div className="">
      <h2 className="mb-4">Estado General</h2>
      
      <div className="row">
        {/* Tarjetas de Resumen */}
        <div className="col-12 col-md-6 col-xl-3">
          <div className="card  shadow text-white mb-3">
            <div className="card-header  bg-primary">Citas Programadas</div>
            <div className="card-body text-dark">
              <h5 className="card-title">{dashboard.citasProgramadas}</h5>
              <p className="card-text">Citas activas en la agenda.</p>
            </div>
          </div>
        </div>

           {/* KPI de Pacientes */}
        <div className="col-12 col-md-6 col-xl-3">
          <div className="card shadow text-white mb-3">
            <div className="card-header  bg-success">Pacientes Registrados</div>
            <div className="card-body text-dark">
              <h5 className="card-title">{dashboard.pacientesRegistrados}</h5>
              <p className="card-text">En total registrados.</p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-xl-3">
          <div className="card shadow text-white mb-3">
            <div className="card-header  bg-info">Pacientes en Curso</div>
            <div className="card-body text-dark">
              <h5 className="card-title">{dashboard.pacientesEnCurso}</h5>
              <p className="card-text">Actualmente en tratamiento.</p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-xl-3">
          <div className="card shadow text-white mb-3">
            <div className="card-header bg-warning ">Ingresos Estimados</div>
            <div className="card-body text-dark">
              <h5 className="card-title">{dashboard.ingresosEstimados}</h5>
              <p className="card-text">Ingresos generados este mes.</p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Gráficos y Reportes */}
      <div className="row mt-4">
        <div className="col-12 col-xl-6">
          <div className="card shadow">
            <div className="card-header">Citas por Semana</div>
            <div className="card-body">
              {
                dashboard.chartCitasPorSemana.categories && dashboard.chartCitasPorSemana.categories.length > 0 ?
                    <Chart
                    type="bar"
                    options={{ xaxis: { categories: dashboard.chartCitasPorSemana.categories } }}
                    series={[{ name: 'Citas', data: dashboard.chartCitasPorSemana.data }]}
                />
                : <h4>Sin datos disponibles</h4>
              }              
            </div>
          </div>
        </div>

        <div className="col-12 col-xl-6">
          <div className="card shadow">
            <div className="card-header">Ingresos por Mes</div>
            <div className="card-body">
              {
                dashboard.chartIngresosPorMes.categories && dashboard.chartIngresosPorMes.categories.length > 0 ?
                    <Chart
                    type="line"
                    options={{ xaxis: { categories: dashboard.chartIngresosPorMes.categories } }}
                    series={[{ name: 'Ingresos ($USD)', data: dashboard.chartIngresosPorMes.data }]}
                  />
                  : <h4>Sin datos disponibles</h4>
              }              
            </div>
          </div>
        </div>
      </div>
      
      {/* Lista de Últimas Citas */}
      <div className="row ">
        <div className="col-12">
          <div className="card shadow">
            <div className="card-header">Últimas Citas</div>
            <div className="card-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Paciente</th>
                    <th>Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    dashboard.patients && dashboard.patients.length > 0 ?
                      dashboard.patients.map((i, index) => {
                        return (
                          <tr key={index}>
                            <td>{i.date_appointment}</td>
                            <td>{i.patient}</td>
                            <td><span className="badge bg-success">{i.status}</span></td>
                          </tr>
                        )
                      })
                      : 
                      null
                  }
                 
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardCustomer;
