import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";

const menuItems = [
  { name: "Dashboard", path: "/admin/dashboard" },
  { name: "Contactos", path: "/admin/contact" },
  {
    name: "Seguridad",
    subItems: [
      { name: "Usuarios", path: "/admin/seguridad/users" },
      { name: "Perfiles", path: "/admin/seguridad/profiles" },
      { name: "Bitácora y registros", path: "/admin/seguridad/records" }
    ]
  },
  {
    name: 'Configuración',
    subItems: [
      { name: "Planes", path: "/admin/configuracion/plans" },
      { name: "Catálogos", path: "/admin/configuracion/catalogs" }
    ]
  }
  
];

function SidebarMenuItem({ item, isOpen, toggleMenu, closeOffcanvas }) {
  if (item.subItems) {
    return (
      <li className="nav-item">
        <a 
          className="nav-link text-white d-flex align-items-center" 
          href="#" 
          role="button" 
          aria-expanded={isOpen} 
          aria-controls={`menu-${item.name}`}
          onClick={(e) => {
            e.preventDefault();
            toggleMenu(item.name);
          }}
        >
          <span className="me-2">{item.name}</span>
          <i className={`bi ${isOpen ? "bi-chevron-up" : "bi-chevron-down"}`}></i>
        </a>
        <div className={`collapse ${isOpen ? "show" : ""}`}>
          <ul className="nav flex-column ms-3">
            {item.subItems.map((subItem, index) => (
              <li className="nav-item" key={index}>
                <Link className="nav-link text-white" to={subItem.path} onClick={() => {
                  closeOffcanvas();
                  toggleMenu(null);
                }}>{subItem.name}</Link>
              </li>
            ))}
          </ul>
        </div>
      </li>
    );
  }
  
  return (
    <li className="nav-item">
      <Link className="nav-link text-white" to={item.path} onClick={closeOffcanvas}>{item.name}</Link>
    </li>
  );
}

function AdminLayout() {
  const [openMenu, setOpenMenu] = useState(null);
  
  const [user, setUser] = useState(null);

  useEffect(() => {
    const offcanvasElement = document.getElementById("sidebarMenu");
    if (offcanvasElement) {
      const offcanvasInstance = new window.bootstrap.Offcanvas(offcanvasElement);
      document.querySelectorAll(".nav-link").forEach(link => {
        if (link.getAttribute("href") !== "#") {
          link.addEventListener("click", () => offcanvasInstance.hide());
        }
      });
    }

    setUser(JSON.parse((sessionStorage.getItem('user') ?? '{}')));
  }, []);

  const toggleMenu = (menuName) => {
    setOpenMenu(prevMenu => (prevMenu === menuName ? null : menuName));
  };

  const salirSesion = () => {
    sessionStorage.clear();
    document.location.assign('/');  
  }

  return (
    <main className="admin-layout">
      <nav style={{ fontFamily: "'Space Grotesk', sans-serif"}}  className="navbar navbar-dark bg-dark fixed-top d-flex justify-content-between px-3">
        <span className="navbar-brand">CareCode Solutions</span>
        <button className="btn btn-outline-light" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebarMenu">
          <i className="bi bi-list"></i> Menu
        </button>
        <Link className="btn btn-danger d-none d-md-block" onClick={salirSesion}>Cerrar Sesión</Link>
      </nav>
      
      <div style={{ fontFamily: "'Work Sans', sans-serif"}}  className="offcanvas offcanvas-start bg-dark text-white" id="sidebarMenu" tabIndex="-1">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">Admin Panel</h5>
          <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas"></button>
        </div>
        <div className="offcanvas-body">
          <ul className="nav flex-column">
            {menuItems.map((item, index) => (
              <SidebarMenuItem 
                key={index} 
                item={item} 
                isOpen={openMenu === item.name} 
                toggleMenu={toggleMenu} 
                closeOffcanvas={() => document.getElementById("sidebarMenu").classList.remove("show")} 
              />
            ))}
          </ul>
        </div>
      </div>
      
      <section  className="main-content px-4 py-2 bg-admin-layout">
        {
          user && <Outlet context={user} />
        }
      </section>
    </main>
  );
}

export default AdminLayout;
