import React, { useEffect, useState } from "react";
import api from "../../utils/api";
import { showLoader, hideLoader } from "../../utils/loader";

function TrailWatch() {
  useEffect(() => {
    document.title = "PsiAdmin | Bitácora y registros";
  }, []);


  const [logs, setLogs] = useState([])

  const [filterUser, setFilterUser] = useState("");
  const [filterAction, setFilterAction] = useState("");
  const [filterPage, setFilterPage] = useState("");

  const getLogs = () => {
    showLoader();
    setLogs([]);

    api.post('/logs-system/list', {
      user_name: filterUser.trim(),
      action: filterAction.trim(),
      page: filterPage.trim(),
      top: 0
    }).then(res => {
        hideLoader();

        if (res.data.length > 0) {
          setLogs(res.data);
        }
    }).catch(err => {
        hideLoader();
        alert(err);
    })
  }
  
  useEffect(() => {
    getLogs();
  }, [])

  return (
    <div className="w-100 mt-4">
      <h2 className="fw-bold mb-4">Registros</h2>

       {/* Filtros */}
       <div className="row mb-3">
          <div className="col-12 col-md-3">
            <label>Usuario</label>
            <input
              type="search"
              className="form-control"
              placeholder="Filtrar por Usuario"
              value={filterUser}
              onChange={(e) => setFilterUser(e.target.value)}
            />
          </div>
          <div className="col-12 col-md-3">
            <label>Acción</label>
            <input
              type="search"
              className="form-control"
              placeholder="Filtrar por Acción"
              value={filterAction}
              onChange={(e) => setFilterAction(e.target.value)}
            />
          </div>
          <div className="col-12 col-md-3">
            <label>Página</label>
            <input
              type="search"
              className="form-control"
              placeholder="Filtrar por Página"
              value={filterPage}
              onChange={(e) => setFilterPage(e.target.value)}
            />
          </div>
          <div className="col-12 col-md-3">
            <div className="d-flex w-100 h-100 align-items-end">
              <button className="btn btn-primary" title="Buscar" onClick={getLogs}>
                <span className="bi bi-search"></span>
                &nbsp;
                Buscar
              </button>
            </div>
          </div>
        </div>
      <div className="card shadow-sm p-3">
       

        {/* Tabla de Logs */}
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Usuario</th>
              <th>Acción</th>
              <th>Página</th>
              <th>Fecha y Hora</th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log) => (
              <tr key={log.id_log_entry}>
                <td>{log.id_log_entry}</td>
                <td>{log.user_name}</td>
                <td>{log.action}</td>
                <td>{log.accessed_page}</td>
                <td>{log.created_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TrailWatch;