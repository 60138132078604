import { useEffect } from "react";

function Home() {
  useEffect(() => {
    document.title = "PsiAdmin | Inicio";
  }, []);

  return (
    <section role="main">
      {/* Hero Section */}
      <section className="text-center pb-5 mt-3">
        <div className="container">
          <h1 className="fw-bold">Gestión Inteligente para Psicólogos y Psiquiatras</h1>
          <p className="lead">Administra citas, historiales y reportes desde una plataforma moderna y fácil de usar.</p>
          <a href="/products" className="btn btn-primary btn-lg shadow-sm">Ver Planes</a>
        </div>
      </section>

      {/* Features Section */}
      <section className="container py-3 py-md-5">
        <div className="d-flex flex-column flex-md-row gap-md-4 justify-content-center align-items-center gap-3">
          <div className="col-md-4"> 
            <div className="card border-0 shadow-sm p-4">
              <i className="bi bi-people fs-1 text-primary"></i>
              <h5 className="mt-3">Un sitio para el especialista de la salud</h5>
              <p className="m-0">Mejora tu experiencia en la gestión de tus pacientes con herramientas útiles para ti.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card border-0 shadow-sm p-4">
              <i className="bi bi-calendar-check fs-1 text-success"></i>
              <h5 className="mt-3">Calendario y gestión de cobros</h5>
              <p className="m-0">Organiza tus citas usando el calendario incorporado y una gestión eficiente de cobros.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card border-0 shadow-sm  p-4">
              <i className="bi bi-diagram-3 fs-1 text-danger"></i>
              <h5 className="mt-3">Gestión Centralizada</h5>
              <p className="m-0 text-justify">Administra toda el proceso de tratamiento de tus pacientes desde un solo lugar.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Beneficios Clave Section */}
      <section className="bg-light py-5 mb-5 bg-opacity-75">
        <div className="container text-center">
          <h2 className="mb-4">Por Qué Elegir PsiAdmin</h2>
          <div className="row justify-content-center">
            <div className="col-md-3 mb-4">
              <i className="bi bi-graph-up-arrow fs-1 text-secondary"></i>
              <h6 className="mt-2">Indicadores en Tiempo Real</h6>
              <p className="text-muted">Visualiza KPIs clave como ingresos, asistencia y sesiones realizadas.</p>
            </div>
            <div className="col-md-3 mb-4">
              <i className="bi bi-bell fs-1 text-secondary"></i>
              <h6 className="mt-2">Recordatorios Automatizados</h6>
              <p className="text-muted">Reduce inasistencias con alertas automáticas.</p>
            </div>
            <div className="col-md-3 mb-4">
              <i className="bi bi-buildings fs-1 text-secondary"></i>
              <h6 className="mt-2">Multi-clínica</h6>
              <p className="text-muted">Administra varias sucursales desde un mismo panel.</p>
            </div>
            <div className="col-md-3 mb-4">
              <i className="bi bi-phone fs-1 text-secondary"></i>
              <h6 className="mt-2">Acceso desde cualquier dispositivo</h6>
              <p className="text-muted">Gestiona tu práctica desde móvil, tablet o PC.</p>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default Home;
