import React, { useState, useEffect } from "react";
import { useAlerts } from "../../components/utils/alerts";
import api from "../../utils/api";
import { showLoader, hideLoader } from "../../utils/loader";

function Users() {
    useEffect(() => {
        document.title = "PsiAdmin | Usuarios";
      }, []);

    const { showAlert } = useAlerts();

    const [userFilter, setUserFilter] = useState({
        correo: '',
        nombre: ''
      });
 
    const [profiles, setProfiles] = useState([])
    const [users, setUsers] = useState([]);   
    const [currentUser, setCurrentUser] = useState({
        id_user: null, 
        name: "", 
        email: "", 
        role: "", 
        id_security_profile: '0', 
        password: '',
        phone: ''
    });
    const [modal, setModal] = useState(null);

    useEffect(() => {
        setModal(new bootstrap.Modal(document.getElementById("userModal")));

        getSecurityProfiles();
        getUsers();
    }, []);

    

    const handleAdd = () => {
        setCurrentUser({ 
            id_user: null, 
            name: "", 
            email: "", 
            role: "", 
            id_security_profile: '0', 
            password: '',
            phone: ''
         });
        modal.show();
    };

    const handleDelete = (user) => {
        if (confirm(`¿Está seguro de eliminar el usuario ${user.name}?`)) {
            showLoader();

            api.post('/users/delete', {
                id_user: user.id_user
            }).then(res => {
                hideLoader();
                
                showAlert('success', 'Usuario eliminado', 'Se actualizó la tabla de usuarios');
                getUsers();
            }).catch(err => {
                hideLoader();

                let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
                showAlert('danger', 'Error', msj );
            })
        }
    };

    const handleEdit = (user) => {
        setCurrentUser({ 
            id_user: null, 
            name: "", 
            email: "", 
            role: "", 
            id_security_profile: '0', 
            password: '',
            phone: ''
         });
        showLoader();

        api.post('/users/get', {
            id_user: user.id_user
        }).then(res => {
            hideLoader();

            setCurrentUser({
                id_user: res.data.id_user, 
                name: res.data.name, 
                email: res.data.email, 
                id_security_profile: res.data.id_security_profile ?? 0, 
                password: '',
                phone: res.data.phone ?? ''
            })

            modal.show();
        }).catch(err => {
            hideLoader();

            let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
            showAlert('danger', 'Error', msj );
        })
   
    };

    const handleSave = () => {
        let msj='';
        if (currentUser.name.trim() === '') {
            msj += '<li>Ingrese el nombre</li>';
        }
        if (currentUser.email.trim() === '') {
            msj += '<li>Ingrese un correo electrónico</li>';
        }
        if (currentUser.password?.trim() === '' && currentUser.id_user === null) {
            msj += '<li>Ingrese un password</li>';
        } else {
            if (currentUser.password?.trim().length < 8 && currentUser.password.trim().length > 0) {
                msj += '<li>El password debe tener mínimo 8 caracteres</li>';
            }
        }
        if (Number(currentUser.id_security_profile) === 0) {
            msj += '<li>Elija el rol para el usuario</li>';
        }

        if (msj.length > 0) {
            showAlert('danger', 'Verifique lo siguiente', msj);
        } else {
            showLoader();
            var ruta = currentUser.id_user ? '/users/update': '/users/insert';
            api.post(ruta, {
                id_user: currentUser.id_user,
                name: currentUser.name,
                email: currentUser.email,
                password: currentUser.password,
                id_security_profile: currentUser.id_security_profile
            }).then(res => {
                hideLoader();
                modal.hide();   
                
                if (currentUser.id_user) {
                    showAlert('success', 'Usuario agregado', 'Se actualizó la tabla de usuarios');
                } else {
                    showAlert('success', 'Usuario actualizado', 'Se actualizó la tabla de usuarios');
                }
                
                getUsers();
            }).catch(err => {
                hideLoader();

                let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
                showAlert('danger', 'Error', msj );
            })
            
        }                
    };

    const handleModelChange = (e, field) => {
        const { value } = e.target;
        
        setUserFilter(prevState => {
            const updatedFilter = {
                ...prevState,
                [field]: value
            };
    
            return updatedFilter;
        });
    };

    const searchUsers = (filter) => {        
        getUsers();
    };


    /**
     * 
     * LLAMADAS APIS
     * 
     */
    function getSecurityProfiles() {
        showLoader();
        api.post('/security-profiles/list', { description: '' }).then(res => {
            hideLoader();

            setProfiles(res.data);
        }).catch(err => {
            hideLoader();

            let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
            showAlert('danger', 'Error', msj );
        })
    }

    function getUsers() {
        setUsers([]);
        showLoader();
        
        api.post(`/users/list`, {
            name: userFilter.nombre.trim(),
            email: userFilter.correo.trim()
        }, {}).then(res => {
            hideLoader();

            if (res.data.length > 0) {
                setUsers(...[res.data])  
            } else {
                setUsers([]);
            }
           
        }).catch(err => {
            hideLoader();

            let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
            showAlert('danger', 'Error', msj );
        })
    }

    return (
        <div className="w-100 mt-4">
        <h2 className="text-start mb-4 fw-bold">Gestión de Usuarios</h2>
        <button className="btn btn-primary mb-3" onClick={handleAdd}>Agregar Usuario</button>

        <div className="row my-2">
            
            <div className="col-12 col-md-4 col-xl-3">
                <label className="">Correo</label>
                <input className="form-control" style={{maxWidth: '100%'}} onInput={(e) => handleModelChange(e, "correo")} defaultValue={userFilter.correo} placeholder="correo@micorreo.com" type="search" />
            </div> 
            <div className="col-12 col-md-4 col-xl-3">
                <label className="">Nombre</label>
                <input className="form-control" style={{maxWidth: '100%'}} onInput={(e) => handleModelChange(e, "nombre")} defaultValue={userFilter.nombre} placeholder="Carlos, Pérez..." type="search" />
            </div> 
            <div className="col col-md-4 col-xl-3">
                <div className="d-flex align-items-end h-100">
                    <button className="btn btn-primary h-auto" onClick={() => {searchUsers(userFilter)}}>
                        <span className="bi bi-search">Buscar</span>
                    </button>
                </div>
            </div>
        </div>

        <div className="col-12 table-responsive">
            <table className="table table-striped table-hover">
                <thead className="table-dark">
                <tr>
                    <th>Acciones</th>
                    <th>ID</th>
                    <th>Nombre</th>
                    <th>Correo</th>
                    <th>Rol</th>                  
                </tr>
                </thead>
                <tbody>
                {users.map((user) => (
                    <tr key={user.id_user}>
                        <td>
                            <button className="btn btn-warning btn-sm me-2" onClick={() => handleEdit(user)}>Editar</button>
                            <button className="btn btn-danger btn-sm" onClick={() => handleDelete(user)}>Eliminar</button>
                        </td>
                        <td>{user.id_user}</td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.role}</td>                    
                    </tr>
                ))}
                </tbody>
            </table>
        </div>

        {/* Modal para Agregar/Editar */}
        <div className="modal fade" id="userModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
                <div className="modal-header text-bg-dark">
                    <h5 className="modal-title">{currentUser.id_user ? "Editar Usuario" : "Agregar Usuario"}</h5>
                    <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <label className="form-label text-dark">Nombre</label>
                    <input type="text" className="form-control" value={currentUser.name} onChange={(e) => setCurrentUser({ ...currentUser, name: e.target.value })} />
                    
                    <label className="form-label text-dark">Teléfono</label>
                    <input type="text" className="form-control" value={currentUser.phone} onChange={(e) => setCurrentUser({ ...currentUser, phone: e.target.value })} />
                    
                    <label className="form-label mt-2 text-dark">Correo</label>
                    <input type="email" className="form-control" value={currentUser.email} onChange={(e) => setCurrentUser({ ...currentUser, email: e.target.value })} />
                    
                    <label className="form-label mt-2 text-dark">Password</label>
                    <input type="password" className="form-control" value={currentUser.password} onChange={(e) => setCurrentUser({ ...currentUser, password: e.target.value })} />
                    
                    <label className="form-label mt-2 text-dark">Rol</label>
                    <select className="form-select"  value={currentUser.id_security_profile} onChange={(e) => setCurrentUser({ ...currentUser, id_security_profile: e.target.value })}>
                        <option value={'0'}>Seleccione</option>
                        {profiles.map((i, index) => {
                            return ( 
                                <option key={index} value={i.id_security_profile}>{i.description}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    <button type="button" className="btn btn-primary" onClick={handleSave}>Guardar</button>
                </div>
            </div>
            </div>
        </div>
        </div>
    );
}

export default Users;
