import React, { useEffect, useState } from "react";
import { useAlerts } from "../../components/utils/alerts";
import api from "../../utils/api";
import { showLoader, hideLoader } from "../../utils/loader";
import { validatePasswordStrength } from "../../utils/validatePassword";

function CuentaSuscripcion() {
  const { showAlert } = useAlerts();
  

  const [userData, setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
  const [diasPrueba, setDiasPruebe] = useState(userData.dias_pruebas);
  useEffect(() => {
    document.title = "PsiAdmin | Cuenta y Suscripción";
  }, []);


  const [keysPaymentType, setKeyPaymentType] = useState([]);
  const [plans, setPlans] = useState([]);

  const [formData, setFormData] = useState({
    nombre: "",
    email: "",
    telefono: "",
    plan: "0",
    metodoPago: "0",
    contrasena: "",
    confirmarContrasena: "",
    notificaciones: {
      email: false,
      whatsapp: false,
      sms: false,
    }
  });



  const handleChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value
    });
  };

  const handlePasswordUpdate = () => {
    if (formData.contrasena !== formData.confirmarContrasena) {
      showAlert('danger', 'Error', 'Las contraseñas no coinciden.');
      return;
    }
    showAlert('success', 'Cambio de contraseña', 'Tu contraseña ha sido actualizada.');
  };

  const handleCancelSubscription = () => {
    showAlert('warning', 'Cancelar Suscripción', 'Tu suscripción será cancelada al final del ciclo de facturación.');
  };

  const handleDeleteAccount = () => {
    showAlert('danger', 'Eliminar Cuenta', 'Esta acción no se puede deshacer. ¿Estás seguro?');
  };

  const handleNotificationChange = (e, type) => {
    setFormData({
      ...formData,
      notificaciones: {
        ...formData.notificaciones,
        [type]: e.target.checked
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    showAlert('success', 'Datos Actualizados', 'Tu información ha sido guardada exitosamente.');
  };



  /***
   * 
   * 
   * CONSUMO DE APIS
   * 
   * 
   */
  useEffect(() => {
    getKeys(6);
    getPlans();
    getUser();
    getSubscription();

    document.querySelectorAll("input").forEach(input => {
      input.setAttribute("autocomplete", "off");
    });    
  }, [])

  function getPlans() {
    setPlans([]);
    showLoader();
    api.post('/plans/list', {
      name: '',
      amount: ''
    }).then(res => {
      hideLoader();

      if (res.data.length > 0) {
        setPlans(res.data);
      }
    }).catch(err => {
      hideLoader();
        
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  }

  const getUser = () => {
    showLoader();
    api.post('/users/get', {
        id_user: userData.id_user
    }).then(res => {
      hideLoader();

      setFormData(prevState => {
        return {
          ...prevState,
          nombre: res.data.name,
          email: res.data.email,
          telefono: res.data.phone ?? ''
        }            
      });
    }).catch(err => {
      hideLoader();

      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  }

  function getSubscription() {
    showLoader();
    api.post('/subscriptions/get', {
      id_subscription: userData.id_subscription
    }).then(res => {
      hideLoader();

      setFormData(prevState => {
        return {
          ...prevState,
          metodoPago: res.data.id_payment_type ?? '0',
          notificaciones: {
            email: res.data.email_notification ?? false,
            sms: res.data.sms_notification ?? false,
            whatsapp: res.data.whatsapp_notificacion ?? false
          }
        }            
      });
    }).catch(err => {
      hideLoader();
        
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  }

  function actualizaUsuario() {
    let msj = '';
    if (formData.nombre.trim() === '') {
      msj += '<li>Ingrese el nombre</li>';
    }
    if (formData.email.trim() === '') {
      msj += '<li>Ingrese un correo elecrónico</li>';
    }

    if (msj.length === 0) {
      showLoader();
      api.post('/users/update', {
        id_user: userData.id_user,
        name: formData.nombre.trim(),
        email: formData.email.trim(),
        phone: formData.telefono.trim(),
        tipo: 1
      }).then(res => {
        hideLoader();
        showAlert('success', 'Datos generales actualizados');
      }).catch(err => {
        hideLoader();

        let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
        showAlert('danger', 'Error', msj );
      })
    } else {
      showAlert('danger', 'Verifique lo siguiente', msj);
    }    
  }

  function actualizaUsuarioPassword() {
    let msj = '';
    if (formData.contrasena.trim() === '') {
      msj += '<li>Ingrese la contraseña</li>';
    } else {
      if (formData.contrasena.trim().length < 8) {
        msj += '<li>La contraseña debe tener mínimo 8 caracteres</li>';
      }
    }
    if (formData.confirmarContrasena.trim() === '') {
      msj += '<li>Confirme la contraseña</li>';
    } else {
      if (formData.confirmarContrasena.trim().length < 8) {
        msj += '<li>La contraseña debe tener mínimo 8 caracteres</li>';
      }
    }

    if (formData.confirmarContrasena.trim() !== '' && formData.contrasena.trim() !== '') {
      if (formData.confirmarContrasena.trim() !== formData.contrasena.trim()) {
        msj += '<li>La contraseña y la confirmación de contraseña no son iguales</li>';
      }

      const errores = validatePasswordStrength(formData.contrasena.trim() );
      msj += errores;
    }

    if (msj.length === 0) {
      showLoader();
      api.post('/users/update', {
        id_user: userData.id_user,
        password: formData.contrasena.trim(),
        tipo: 2
      }).then(res => {
        hideLoader();
        showAlert('success', 'Contraseña actualizada');
        setFormData(prevState => {
          return {
            ...prevState,
            contrasena: '',
            confirmarContrasena: ''
          }            
        });

      }).catch(err => {
        hideLoader();

        let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
        showAlert('danger', 'Error', msj );
      })
    } else {
      showAlert('danger', 'Verifique lo siguiente', msj);
    }    
  }

  function getKeys(id_key_type) {
    showLoader();    

    api.post('/keys/list', {
      system_key: 0,
      id_key_type: id_key_type
    }).then(res => {
      hideLoader();

      switch (id_key_type) {
        case 6:
          setKeyPaymentType(res.data);
          break;
      }
    }).catch(err => {
      hideLoader();      
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  }

  function updateNotificationSubscription() {
    showLoader();
    api.post('/subscriptions/notifications-update', {
      id_subscription: userData.id_subscription,
      email_notification: formData.notificaciones.email,
      sms_notification: formData.notificaciones.sms,
      whatsapp_notificacion: formData.notificaciones.whatsapp,
      id_user: userData.id_user
    }).then(res => {
      hideLoader();
      showAlert('success', 'Notificaciones actualizadas');
    }).catch(err => {
      hideLoader();

      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  }

  return (
    <div className="text-white p-4" style={{ backgroundColor: "rgba(0,0,0, .5)"}}>
      <div class="alert alert-info alert-dismissible fade show" role="alert">
        ¡Tiene <b>{diasPrueba}</b> días de pruebas restantes!
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>

      <h2 className="mb-4">Cuenta y Suscripción</h2>
      
      <div className="row g-3" autoComplete="off">
        {/* Información Personal */}
        <div className="border border-2 p-3 mb-4">
          <legend className="w-auto">Información Personal</legend>
          <div className="row">
            <div className="col-md-6">
              <label className="form-label">Nombre Completo</label>
              <input type="text" className="form-control" placeholder="Juan Pérez" value={formData.nombre} onChange={(e) => handleChange(e, "nombre")} autoComplete="off" autoCorrect="off" />
            </div>
            <div className="col-md-6">
              <label className="form-label">Correo Electrónico</label>
              <input type="email" className="form-control" placeholder="juan@perez.com" value={formData.email} onChange={(e) => handleChange(e, "email")} autoComplete="off" autoCorrect="off" />
            </div>
            <div className="col-md-6">
              <label className="form-label">Teléfono</label>
              <input type="text" className="form-control" placeholder="55 1122 3344" value={formData.telefono} onChange={(e) => handleChange(e, "telefono")} autoComplete="new-use" autoCorrect="off"/>
            </div>
          </div>
           {/* Acciones */}
           <div className="col-12">
            <button type="button" className="btn btn-primary" onClick={actualizaUsuario}>Actualizar Datos Generales</button>
          </div>
        </div>

        {/* Suscripción */}
        <div className="border border-2  p-3 mb-4">
          <legend className="w-auto">Suscripción</legend>
          <div className="row">
            <div className="col-md-6">
              <label className="form-label">Plan</label>
              <select className="form-select" disabled value={formData.plan} onChange={(e) => handleChange(e, "plan")}>
                {
                  plans.map((i, index) => {
                    return (
                      <option key={index} value={i.id_plan}>{i.name}</option>
                    )
                  })
                }
              </select>
            </div>
            <div className="col-md-6">
              <label className="form-label">Método de Pago</label>
              <select className="form-select" value={formData.metodoPago} onChange={(e) => handleChange(e, "metodoPago")}>
                <option value="0">Seleccione</option>
                {
                  keysPaymentType.map((i, index) => {
                    return (
                      <option key={index} value={i.id_key}>{i.value}</option>
                    )
                  })
                }
              </select>
            </div>
          </div>
          {/* Acciones */}
          <div className="col-12 gap-3 d-flex flex-md-row flex-column">
            <button type="button" className="btn btn-primary" disabled>Actualizar Suscripción</button>
            <button type="button" className="btn btn-warning" disabled onClick={handleCancelSubscription}>
              Cancelar Suscripción
            </button>
            <button type="button" className="btn btn-danger" disabled onClick={handleDeleteAccount}>
              Eliminar Cuenta
            </button>
          </div>
        </div>

        {/* Seguridad */}
        <div className="border border-2  p-3 mb-4">
          <legend className="w-auto">Seguridad</legend>
          <div className="col-md-6">
            <label className="form-label">Nueva Contraseña</label>
            <input type="password" className="form-control" placeholder="********" value={formData.contrasena}  autoComplete="off" autoCorrect="off" onChange={(e) => handleChange(e, "contrasena")} />
          </div>
          <div className="col-md-6">
            <label className="form-label">Confirmar Contraseña</label>
            <input type="password" className="form-control" placeholder="*********" value={formData.confirmarContrasena}autoComplete="off" autoCorrect="off" onChange={(e) => handleChange(e, "confirmarContrasena")} />
          </div>
          <div className="col-12 mt-3">
            <button type="button" className="btn btn-primary" onClick={actualizaUsuarioPassword}>
              Actualizar Contraseña
            </button>
          </div>
        </div>

        {/* Notificaciones */}
        <div className="border border-2  p-3 mb-4">
          <legend className="w-auto">Notificaciones</legend>
          <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" checked={formData.notificaciones.email} onChange={(e) => handleNotificationChange(e, "email")} autoComplete="off"/>
            <label className="form-check-label">Recibir notificaciones por Email</label>
          </div>
          <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" checked={formData.notificaciones.whatsapp} onChange={(e) => handleNotificationChange(e, "whatsapp")} autoComplete="off"/>
            <label className="form-check-label">Recibir notificaciones por WhatsApp</label>
          </div>
          <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" checked={formData.notificaciones.sms} onChange={(e) => handleNotificationChange(e, "sms")} autoComplete="off"/>
            <label className="form-check-label">Recibir notificaciones por SMS</label>
          </div>
          <div className="col-12 mt-3">
            <button type="button" className="btn btn-primary" onClick={updateNotificationSubscription}>
              Actualizar notificaciones
            </button>
          </div>
        </div>      
        
      </div>
    </div>
  );
}

export default CuentaSuscripcion;
