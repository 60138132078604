import React, { useState, useEffect } from "react";
import { useAlerts } from "../../components/utils/alerts";
import { hideLoader, showLoader } from "../../utils/loader";
import api from "../../utils/api";



function Profiles() {
  useEffect(() => {
    document.title = "PsiAdmin | Perfiles";
  }, []);


  const { showAlert } = useAlerts();
  const [profiles, setProfiles] = useState([]);
  const [profileFilter, setProfileFilter] = useState({ role: "" });
  const [currentProfile, setCurrentProfile] = useState({ id_security_profile: null, description: "", permissions: "" });
  const [modal, setModal] = useState(null);

  useEffect(() => {
    getProfiles();
    setModal(new window.bootstrap.Modal(document.getElementById("profileModal")));
  }, []);

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setProfileFilter({ role: value });
    // searchProfiles(value);
  };



  const handleDelete = (id) => {
    setProfiles(profiles.filter(profile => profile.id !== id));
    setFilteredProfiles(filteredProfiles.filter(profile => profile.id !== id));
  };

  const handleEdit = (profile) => {
    showLoader();

    api.post('security-profiles/get', {
      id_security_profile: profile.id_security_profile
    }).then(res => {
      hideLoader();

      setCurrentProfile(res.data);

      modal.show();
    }).catch(err => {
      hideLoader();
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  };

  const handleAdd = () => {
    setCurrentProfile({ id_security_profile: null, description: "", permissions: "" });
    modal.show();
  };
  
  const handleSave = () => {
    let msj = '';
    if (currentProfile.description.trim() === '') {
      msj += '<li>Ingrese el nombre del perfil</li>';
    }
    if (msj.trim() !== '') {
      showAlert('danger', 'Verifique lo siguiente', msj);
    } else {
      showLoader();

      var ruta = currentProfile.id_security_profile === null ? '/security-profiles/insert': '/security-profiles/update';

      api.post(ruta, {
        id_security_profile: currentProfile.id_security_profile,
        description: currentProfile.description
      }).then(res => {
        hideLoader();

        if (currentProfile.id_security_profile) {
          showAlert('success', 'Perfil actualizado', 'La tabla de perfiles se actualizó');
        } else {
          showAlert('success', 'Perfil creado', 'La tabla de perfiles se actualizó');
        }
        getProfiles();
        modal.hide();

      }).catch(err => {
        hideLoader();
        let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
        showAlert('danger', 'Error', msj );
      }) 
    }
  };


  /***
   * 
   * 
   * LLAMADAS A APIS
   * 
   * 
   */

  const getProfiles = () => {
    showLoader();
    setProfiles([]);

    api.post('security-profiles/list', {
      description: profileFilter.role.trim()
    }).then(res => {
      hideLoader();

      if (res.data.length > 0) {
        setProfiles(res.data);
      }
   
    }).catch(err => {
      hideLoader();
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  };

  return (
    <div className="w-100 mt-4">
      <h2 className="mb-4 fw-bold">Gestión de Perfiles de Seguridad</h2>

      <div className="row">
        <div className="col-12">
                <button className="btn w-fit btn-success" onClick={handleAdd}>
                  <span className="bi bi-plus-square">&nbsp;</span>  Agregar Perfil
                </button>
        </div>
        <div className="col-12 col-md-4 col-xl-3">
            <label>Rol</label>
            <input className="form-control" placeholder="Buscar por rol" type="search" onInput={handleFilterChange} />
        </div>
        <div className="col col-md-4 col-xl-3">
            <div className="d-flex align-items-end h-100">
                <button className="btn btn-primary h-auto" onClick={getProfiles}>
                    <span className="bi bi-search">&nbsp;Buscar</span>
                </button>
            </div>
        </div>
        
        <div className="col-12 table-responsive my-2">
            <table className="table table-striped table-hover">
                <thead className="table-dark">
                    <tr>
                        <th>Acciones</th>
                        <th>ID</th>
                        <th>Rol</th>
                        <th>Fecha Creación</th>
                        <th>Fecha Última Modificación</th>
                        {/* <th>Permisos</th> */}
                    </tr>
                </thead>
                <tbody>
                    {profiles.map((profile) => (
                        <tr key={profile.id_security_profile}>
                            <td>
                                <button className="btn btn-warning btn-sm me-2" onClick={() => handleEdit(profile)}>Editar</button>
                            </td>
                            <td>{profile.id_security_profile}</td>
                            <td>{profile.description}</td>
                            <td>{profile.created_at}</td>
                            <td>{profile.updated_at}</td>
                            {/* <td>
                                <ul>
                                {profile.permissions.map((permission, index) => (
                                    <li key={index}>{permission}</li>
                                ))}
                                </ul>
                            </td>                         */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
      </div>
   

      {/* Modal para Agregar/Editar Perfil */}
      <div className="modal fade" id="profileModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header text-bg-dark">
              <h5 className="modal-title">{currentProfile.id_security_profile ? "Editar Perfil" : "Agregar Perfil"}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <label className="form-label text-dark">Rol</label>
              <input type="text" className="form-control" value={currentProfile.description} onChange={(e) => setCurrentProfile({ ...currentProfile, description: e.target.value })} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
              <button type="button" className="btn btn-primary" onClick={handleSave}>Guardar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profiles;