// src/api.js
import axios from 'axios';

// const BASE_URL = 'http://localhost:8000'; // cambia según tu entorno
const BASE_URL = 'https://api.consultas-cuautitlan.app'

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Interceptor para agregar current_id_user a cada body JSON (POST, PUT)
api.interceptors.request.use(config => {
  const user = sessionStorage.getItem('user');
  let id_user = 0;

  if (user !== null) {
    id_user = JSON.parse(user).id_user;
  } else {
    id_user = Number(sessionStorage.getItem('id_user'));
  }

  if (config.method === 'post' || config.method === 'put') {
    const originalData = config.data || {};
    config.data = {
      ...originalData,
      current_id_user: (id_user ?? '')
    };
  }

  return config;
}, error => {
  return Promise.reject(error);
});



export default api;
