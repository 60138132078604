import React, { useState, useEffect, useRef } from "react";
import { useAlerts } from "../../components/utils/alerts";
import ModalAgendarCita from "../../components/modals/NewCite";
import api from "../../utils/api";
import { showLoader, hideLoader } from "../../utils/loader";
import { useNavigate } from "react-router-dom";

function Pacientes() {
  const navigate = useNavigate();


  useEffect(() => {
    document.title = "PsiAdmin | Pacientes";

    setUser(JSON.parse(sessionStorage.getItem('user')));     
  }, []);

  const [userData, setUser] = useState(null);
  const [id_psychologist, setIdPpsychologist] = useState(null)
  const { showAlert } = useAlerts();
  const modalRefPaciente = useRef(null); 
  const [modalPaciente, setModalPaciente] = useState(null);
  const [modalCita, setModalCita] = useState(null);

  

  useEffect(() => {
    if (userData !== null) {
      setIdPpsychologist(userData.id_psychologist);
    }
  }, [userData])

  useEffect(() => {
    if (id_psychologist !== null) {
      if(id_psychologist !== null) {
        getPatients();
      }
    }
  }, [id_psychologist])



  const [pacienteCita, setPacienteCita] = useState(null);
  const [pacientes, setPacientes] = useState([])

  const [nuevoPaciente, setNuevoPaciente] = useState({
    name: '',
    dob: '',
    email: '',
    phone: '',
    adress: '',
    id_gender: '0',
    emergency_contact: '',
    id_status: '',
    id_psychologist: id_psychologist,
    id_patient: null
  });
  
  const [filtro, setFiltro] = useState({
    nombre: "",
    edad: "",
    correo: ""
  });

  const [ patientsStatus, setPatientsStatus] = useState([]);
  const [ genderStatus, setGenderStatus] = useState([])

  useEffect(() => {
    setModalCita(new bootstrap.Modal(document.getElementById('modalAgendarCita')));
    setModalPaciente(new bootstrap.Modal(modalRefPaciente.current));  
  }, []);



  const handleOpenCitaModal = (paciente) => {
    setPacienteCita(paciente);
    modalCita.show();
  };

  const handleAgendarCita = (cita) => {
    showAlert("success", "Cita Agendada", "La cita ha sido registrada correctamente.");
    modalCita.hide();
  };



  const handleChange = (e, field) => {
    setNuevoPaciente({ ...nuevoPaciente, [field]: e.target.value });
  };
  
  const abreModalPaciente = () => {
    setNuevoPaciente({
      name: '',
      dob: '',
      email: '',
      phone: '',
      adress: '',
      id_gender: '0',
      emergency_contact: '',
      id_status: '',
      id_psychologist: 0,
      id_patient: null
    })
    modalPaciente.show();
  }

  const savePaciente = () => {
    let msj = '';
    nuevoPaciente.id_psychologist = id_psychologist;

    if (!nuevoPaciente.name || nuevoPaciente.name.trim() === '') {
      msj += '<li>Ingrese el nombre del paciente</li>';
    }
  
    if (!nuevoPaciente.dob) {
      msj += '<li>Seleccione la fecha de nacimiento</li>';
    }
  
    if (!nuevoPaciente.phone || nuevoPaciente.phone.trim() === '') {
      msj += '<li>Ingrese el número de teléfono</li>';
    }
  
    // if (!nuevoPaciente.address || nuevoPaciente.address.trim() === '') {
    //   msj += '<li>Ingrese la dirección</li>';
    // }
  
    if (!nuevoPaciente.id_gender || Number(nuevoPaciente.id_gender) === 0) {
      msj += '<li>Ingrese el género</li>';
    }
  
    if (!nuevoPaciente.emergency_contact || nuevoPaciente.emergency_contact.trim() === '') {
      msj += '<li>Ingrese el contacto de emergencia</li>';
    }
  
    if (!nuevoPaciente.id_status || Number(nuevoPaciente.id_status) === 0) {
      msj += '<li>Seleccione un estatus válido</li>';
    }
  
    if (msj !== '') {
      showAlert('danger', 'Verifique lo siguiente', msj);
      return;
    }

    let ruta = nuevoPaciente.id_patient === null ? '/patients/insert': '/patients/udpate';
    showLoader();
    api.post(ruta, nuevoPaciente).then(res => {
      hideLoader();

      if (nuevoPaciente.id_patient === null) {
        showAlert('success', 'Nuevo paciente agregado');
      } else {
        showAlert('success', 'Paciente actualizado');
      }

      modalPaciente.hide();
      getPatients();
    }).catch(err => {
      hideLoader();      
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  };

  function getPatients() {
    showLoader();
    api.post('/patients/list', {
      name: filtro.nombre.trim(),
      email: filtro.correo.trim(),
      id_psychologist: id_psychologist
    }).then(res => {
      hideLoader();

      if (res.data.length > 0) {        
        setPacientes(res.data);
      }
    }).catch(err => {
      hideLoader();      
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  }
  
  const handleFiltroChange = (e, field) => {
    setFiltro({ ...filtro, [field]: e.target.value });
  };


  /***
   * 
   * LLAMDAS A APIS
   * 
   */
  useEffect(() => {
    getKeys(5);
    getKeys(7);
   
  }, []);

  function getKeys(id_key_type) {
    showLoader();    

    api.post('/keys/list', {
      system_key: 0,
      id_key_type: id_key_type
    }).then(res => {
      hideLoader();

      switch (id_key_type) {
        case 5:
          setPatientsStatus(res.data);
          break;
        case 7:
          setGenderStatus(res.data);
          break;
      }
    }).catch(err => {
      hideLoader();      
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  }


  return (
    <div className="text-white p-4" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", borderRadius: "10px" }}>
      <h2 className="mb-4">Gestión de Pacientes</h2>

      {/* Botón para abrir modal */}
      <button className="btn btn-primary mb-3" onClick={abreModalPaciente}>Agregar Paciente</button>
      
      {/* Filtros */}
      {/* <div className="row mb-3">
        <div className="col-md-4">
          <input type="text" className="form-control" placeholder="Filtrar por nombre" value={filtro.nombre} onChange={(e) => handleFiltroChange(e, "nombre")} />
        </div>
        <div className="col-md-4">
          <input type="number" className="form-control" placeholder="Filtrar por edad" value={filtro.edad} onChange={(e) => handleFiltroChange(e, "edad")} />
        </div>
        <div className="col-md-4">
          <input type="email" className="form-control" placeholder="Filtrar por correo" value={filtro.correo} onChange={(e) => handleFiltroChange(e, "correo")} />
        </div>
      </div> */}

      {/* Lista de Pacientes */}
      <fieldset className="border px-2 table-responsive">
        <legend className="w-auto">Lista de Pacientes</legend>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Acciones</th>
              <th>Nombre</th>
              <th>Edad</th>
              <th>Teléfono</th>
              <th>Correo</th>
              
            </tr>
          </thead>
          <tbody>
            {pacientes.map((paciente, index) => (
              <tr key={index}>
                <td>
                  <button className="btn btn-success btn-sm me-2" onClick={() => {handleOpenCitaModal(paciente)}}>Agendar Cita</button>
                  {/* <button className="btn btn-warning btn-sm me-2">Editar</button>
                  <button className="btn btn-danger btn-sm">Eliminar</button> */}
                </td>
                <td>{paciente.name}</td>
                <td>{paciente.age}</td>
                <td>{paciente.phone}</td>
                <td>{paciente.email}</td>
                
              </tr>
            ))}
          </tbody>
        </table>
      </fieldset>

      {/* Modal de Pacientes */}
      <div className="modal fade"  data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-hidden="true" ref={modalRefPaciente}>
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content text-dark">
            <div className="modal-header">
              <h5 className="modal-title">Agregar Paciente</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body text-dark">
              <div className="row g-3">
                <div className="col-12 col-md-6 col-lg-4">
                  <label>Nombre</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nombre"
                    value={nuevoPaciente.name}
                    onChange={(e) => handleChange(e, "name")}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <label>Fecha de nacimiento</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Fecha de nacimiento"
                    value={nuevoPaciente.dob}
                    onChange={(e) => handleChange(e, "dob")}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <label>Correo</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Correo"
                    value={nuevoPaciente.email}
                    onChange={(e) => handleChange(e, "email")}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <label>Teléfono</label>
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Teléfono"
                    value={nuevoPaciente.phone}
                    onChange={(e) => handleChange(e, "phone")}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-8">
                  <label>Dirección</label>
                  <textarea
                    className="form-control"
                    placeholder="Dirección"
                    value={nuevoPaciente.address}
                    onChange={(e) => handleChange(e, "address")}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <label>Género</label>
                  <select value={nuevoPaciente.id_gender} className="form-select" onChange={(e) => handleChange(e, "id_gender")} >
                    <option value={'0'}>Seleccione</option>
                      {
                        genderStatus.map((i, index) => {
                          return (
                            <option key={index} value={i.id_key}>{i.value}</option>
                          )
                        })
                      }
                  </select>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <label>Contacto de emergencia</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Contacto de emergencia"
                    value={nuevoPaciente.emergency_contact}
                    onChange={(e) => handleChange(e, "emergency_contact")}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <label>Estatus</label>
                  <select value={nuevoPaciente.id_status} className="form-select" onChange={(e) => handleChange(e, "id_status")} >
                    <option value={'0'}>Seleccione</option>
                    {
                      patientsStatus.map((i, index) => {
                        return (
                          <option key={index} value={i.id_key}>{i.value}</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cerrar
              </button>
              <button type="button" className="btn btn-primary" onClick={() => {savePaciente()}}>
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>


      <ModalAgendarCita paciente={pacienteCita} id_psychologist={id_psychologist} onAgendar={handleAgendarCita} />
    </div>
  );
}

export default Pacientes;