import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import api from "../../utils/api"
import { useAlerts } from "../../components/utils/alerts";
import { showLoader, hideLoader } from "../../utils/loader";
import { useOutletContext } from "react-router-dom";

function Dashboard() {
  const usuario = useOutletContext();
  const { showAlert } = useAlerts();

  useEffect(() => {
    document.title = "PsiAdmin | Dashboard";
  }, []);


  const [kpis, setKpis] = useState({
    usuariosRegistrados: 0,
    totalIngresos: '',
    ultimoAcceso: '',
    accesoAnterior: '',
    numeroAlertas: 0
  })

  const [chartVentas, setChartVentas ] = useState({
    categories: [],
    data: []
  })
  const [logs, setLogs] = useState([])

  const getTotalIncome = () => {
    showLoader();
    api.post(`/dashboard/sum-income`, {}).then(res => {
   
      if (res.data.length > 0) {
        setChartVentas({
          categories: res.data.mes,
          data: res.data.total_ventas
        })
      }
     
      hideLoader();
    }).catch(err => {
      hideLoader();

      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    });
  }

  const getKpis = () => {
    showLoader();
    api.post(`/dashboard/kpis`, {
      id_user: usuario.id_user
    }).then(res => {
      setKpis({
        usuariosRegistrados: res.data.total_usuarios,
        totalIngresos: new Intl.NumberFormat('es-MX', {
          style: 'currency',
          currency: 'MXN',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(res.data.ingresos_totales),
        ultimoAcceso: res.data.ultimo_acceso,
        accesoAnterior: res.data.acceso_anterior,
        numeroAlertas: res.data.alertas_activas
      })
      hideLoader();
    }).catch(err => {
      hideLoader();

      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    });
  }

  const getLogs = () => {
    showLoader();
    setLogs([]);

    api.post('/logs-system/list', {
      user_name: '',
      action: '',
      page: '',
      top: 50
    }).then(res => {
        hideLoader();

        if (res.data.length > 0) {
          setLogs(res.data);
        }
    }).catch(err => {
      hideLoader();

      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  }
  
  useEffect(() => {
    getKpis();
    getTotalIncome();
    getLogs();
  }, []);

  return (
    <div className="w-100 mt-4">
      <h2 className="fw-bold mb-4">Panel de Control</h2>

      {/* Sección de Tarjetas */}
      <div className="row">
        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card border-light shadow-sm p-3">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="mb-0 text-dark">Usuarios</h6>
              <i className="bi bi-people fs-2 text-primary"></i>
            </div>
            <h3 className="fw-bold mt-2">{kpis.usuariosRegistrados}</h3>
            <p className="text-muted small">Registrados</p>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card border-light shadow-sm p-3">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="mb-0 text-dark">Ventas</h6>
              <i className="bi bi-cash-stack fs-2 text-success"></i>
            </div>
            <h3 className="fw-bold mt-2">{kpis.totalIngresos}</h3>
            <p className="text-muted small">Ingresos Totales</p>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card border-light shadow-sm p-3">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="mb-0 text-dark">Último acceso</h6>
              <i className="bi bi-file-earmark-bar-graph fs-2 text-warning"></i>
            </div>
            <h3 className="fw-bold mt-2">{kpis.ultimoAcceso}</h3>
            <p className="text-muted small">Anterior: {kpis.accesoAnterior}</p>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card border-light shadow-sm p-3">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="mb-0 text-dark">Alertas del sistema</h6>
              <i className="bi bi-life-preserver fs-2 text-danger"></i>
            </div>
            <h3 className="fw-bold mt-2">{kpis.numeroAlertas}</h3>
            <p className="text-muted small">Nuevas alertas</p>
          </div>
        </div>
      </div>

      {/* Sección de Gráficos y Actividad */}
      <div className="row">
        {/* Gráfica de Ventas */}
        <div className="col-lg-6 mb-4">
          <div className="card border-light shadow-sm p-3" style={{maxHeight: '333px', minHeight:'333px'}}>
            <h6 className="fw-bold mb-3">Resumen de Ventas</h6>
              {
                chartVentas.data && chartVentas.data.length > 0  ?
                  <Chart
                  options={{ chart: { id: "ventas-chart" }, xaxis: { categories: chartVentas.categories } }}
                  series={[{ name: "Ventas", data: chartVentas.data }]}
                  type="line"
                  height={250}
                  /> 
                :
                <h4>Sin datos disponibles</h4> 
              }
          </div>
        </div>

        {/* Últimos Movimientos */}
        <div className="col-lg-6 mb-4">
          <div className="card border-light shadow-sm p-3 " >
            <h6 className="fw-bold mb-3">Registro de Movimientos</h6>
            <ul className="list-group list-group-flush overflow-y-auto" style={{maxHeight: '265px'}}>
              {logs.map((i, index) => {
                return (
                  <li key={index} className="list-group-item">🔹<b>{i.action}</b>&nbsp;por&nbsp;<i>{i.user_name ?? i.other}</i>&nbsp;el&nbsp;<u>{i.created_at}</u></li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>

      {/* Sección de Accesos Rápidos y Alertas */}
      <div className="row">
        <div className="col-lg-6 mb-4">
          <div className="card border-light shadow-sm p-3">
            <h6 className="fw-bold mb-3">Accesos Rápidos</h6>
            <div className="d-flex flex-wrap gap-3">
              <a href="/admin/seguridad/users" className="btn btn-outline-primary">👥 Usuarios</a>
              <a href="/admin/configuracion/plans" className="btn btn-outline-warning">⚙️ Planes</a>
              <a href="/admin/seguridad/records" className="btn btn-outline-danger">🎫 Bitácoras</a>
            </div>
          </div>
        </div>

        <div className="col-lg-6 mb-4">
          <div className="card border-light shadow-sm p-3">
            <h6 className="fw-bold mb-3">Alertas del Sistema</h6>
            <div className="alert">Sin alertas en los ultimos 30 días.</div>
            {/* <div className="alert alert-warning">⚠️ Renovación de suscripción pendiente en 3 días.</div>
            <div className="alert alert-danger">❌ Fallo en la sincronización de pagos.</div>
            <div className="alert alert-info">ℹ️ Nueva actualización del sitio el 01/01/0000.</div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
