import React, { useEffect, useState } from "react";
import api from "../../utils/api";
import { showLoader, hideLoader } from "../../utils/loader";
import { useAlerts } from "../../components/utils/alerts";

function Planes() {
  const { showAlert } = useAlerts();

  useEffect(() => {
    document.title = "PsiAdmin | Planes";
  }, []);

  const [planes, setPlanes] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', password: '' });

  useEffect(() => {
    api.post("/plans/list", {
      name: '',
      amount: ''
    }).then(res => {
        if (Array.isArray(res.data)) {
          setPlanes(res.data);
        }
      })
      .catch(err => {
        console.error("ERROR_LIST_PLANS");
        console.error(err);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const contratarPlan = async () => {
    try {
      let msj = '';
      if (formData.name.trim() === '') {
        msj += '<li>Ingrese el nombre</li>';
      }
      if (formData.email.trim() === '') {
        msj += '<li>Ingrese el correo electrónico</li>';
      }
      if (formData.phone.trim() === '') {
        msj += '<li>Ingrese el número de teléfono</li>';
      }
      if (formData.password.trim() === '' || formData.password.trim().length < 8) {
        msj += '<li>Ingrese un password válido (mínimo 8 caracteres)</li>';
      }

      if (msj.trim() !== '') {
        showAlert('danger', 'Verifique lo siguiente', msj);
        return;
      }

      showLoader();
      const res = await api.post("/subscriptions/insert", {
        ...formData,
        id_plan: selectedPlan.id_plan
      });

      hideLoader();
      showAlert('success', '¡Suscripción exitosa!', 'Se ha creado tu cuenta correctamente. Ya puedes acceder con tus credenciales ingresadas');
      setFormData({ name: '', email: '', phone: '', password: '' });
      setSelectedPlan(null);
      
      const modal = bootstrap.Modal.getInstance(document.getElementById('modalSubscription'));
      if (modal) modal.hide();
    } catch (error) {
      hideLoader();
      showAlert('danger', 'Error al suscribirse', error.response?.data || error.message);
    }
  };

  return (
    <>
      <div className="bg-primary text-white text-center py-3 rounded-0">
        <p className="mb-0 fw-semibold px-0 mx-0">Todos los planes incluyen un período de prueba de 30 días naturales. ¡Aprovecha esta oferta limitada!</p>
        <p><small><i>Al término de los 30 días naturales la suscripción será renovada con el precio del plan contratado. Sujeto a cambios sin previos. Precios en USD más IVA, sujeto al tipo de cambio del DOF del día 30.</i></small></p>
      </div>
      <section className="container py-5">
        <h1 className="text-center fw-bold mb-2">Nuestros Planes</h1>
        <div className="row justify-content-center g-5 mt-1">
          {planes.map((plan, i) => (
            <div className="col-lg-4 d-flex mt-4" key={i}>
              <div className="border rounded-4 shadow-sm w-100 d-flex flex-column p-4 bg-white position-relative">
                <h5 className="text-center text-primary fw-bold mb-2">{plan.name}</h5>
                <h2 className="text-center fw-bold mb-1">
                  {plan.custom_price
                    ? "Precio personalizado"
                    : `$${parseFloat(plan.amount).toFixed(2)} USD / mes`}
                </h2>
                <p className="text-center text-muted small mb-4">Pago mensual + IVA</p>
                <hr />
                <ul className="list-unstyled px-2 pt-2 text-center pb-4 flex-grow-1">
                  {Array.isArray(plan.features) && plan.features.map((f, idx) => (
                    <li key={idx} className="d-flex align-items-center px-md-4 px-1 mb-2">
                      <i className="bi bi-check-circle-fill text-success me-2 fs-5"></i>
                      <span className="small fs-6 text-start align-items-center d-flex h-100">{f.feature}</span>
                    </li>
                  ))}
                </ul>
                <div className="text-center mt-auto">
                  <button className="btn btn-outline-primary rounded-pill px-4" data-bs-toggle="modal" data-bs-target="#modalSubscription" onClick={() => setSelectedPlan(plan)}>
                    {plan.custom_price ? "Contactar" : "Elegir Plan"}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Modal */}
      <div className="modal fade" id="modalSubscription" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Contratar Plan</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body">
              {selectedPlan && <p className="fw-bold">Plan seleccionado: {selectedPlan.name}</p>}
              <input type="text" name="name" className="form-control mb-2" placeholder="Nombre completo" value={formData.name} onChange={handleChange} />
              <input type="email" name="email" className="form-control mb-2" placeholder="Correo electrónico" value={formData.email} onChange={handleChange} />
              <input type="tel" name="phone" className="form-control mb-2" placeholder="Teléfono" value={formData.phone} onChange={handleChange} />
              <input type="password" name="password" className="form-control mb-2" placeholder="Contraseña" value={formData.password} onChange={handleChange} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
              <button type="button" className="btn btn-primary" onClick={contratarPlan}>Contratar</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Planes;
