import { Navigate, useLocation } from 'react-router-dom';

export default function RequireAuthCustomer({ children }) {
  const location = useLocation();
  const isAuthenticated = sessionStorage.getItem('user') !== null;

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
