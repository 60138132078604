import React, { useEffect } from "react";

function QA() {
  useEffect(() => {
    document.title = "PsiAdmin | Q&A";
  }, []);


  return (
    <>
      {/* QA Section */}
      <section className="container pb-5">
        <h2 className="text-center mb-4">Preguntas Frecuentes</h2>
        <div className="accordion" id="qaAccordion">
          
          {/* Pregunta 1 */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#question1">
                ¿Cómo puedo registrarme?
              </button>
            </h2>
            <div id="question1" className="accordion-collapse collapse show" data-bs-parent="#qaAccordion">
              <div className="accordion-body text-dark bg-secondary bg-opacity-25">
                Puedes registrarte haciendo clic en el botón de registro en la página principal.
              </div>
            </div>
          </div>

          {/* Pregunta 2 */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question2">
                ¿Cuáles son los métodos de pago aceptados?
              </button>
            </h2>
            <div id="question2" className="accordion-collapse collapse" data-bs-parent="#qaAccordion">
              <div className="accordion-body text-dark bg-secondary bg-opacity-25">
                Aceptamos tarjetas de crédito, débito y PayPal.
              </div>
            </div>
          </div>

          {/* Pregunta 3 */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question3">
                ¿Puedo cancelar mi suscripción en cualquier momento?
              </button>
            </h2>
            <div id="question3" className="accordion-collapse collapse" data-bs-parent="#qaAccordion">
              <div className="accordion-body text-dark bg-secondary bg-opacity-25">
                Sí, puedes cancelar tu suscripción en cualquier momento desde la configuración de tu cuenta.
              </div>
            </div>
          </div>

          {/* Pregunta 4 */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question4">
                ¿El sistema es compatible con dispositivos móviles?
              </button>
            </h2>
            <div id="question4" className="accordion-collapse collapse" data-bs-parent="#qaAccordion">
              <div className="accordion-body text-dark bg-secondary bg-opacity-25">
                Sí, nuestra plataforma está optimizada para computadoras, tablets y smartphones.
              </div>
            </div>
          </div>

          {/* Pregunta 5 */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question5">
                ¿Cómo puedo cambiar mi dirección de correo electrónico?
              </button>
            </h2>
            <div id="question5" className="accordion-collapse collapse" data-bs-parent="#qaAccordion">
              <div className="accordion-body text-dark bg-secondary bg-opacity-25">
                Puedes cambiar tu dirección de correo en la sección de configuración de tu cuenta.
              </div>
            </div>
          </div>

          {/* Pregunta 6 */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question6">
                ¿Puedo agregar múltiples usuarios a mi cuenta?
              </button>
            </h2>
            <div id="question6" className="accordion-collapse collapse" data-bs-parent="#qaAccordion">
              <div className="accordion-body text-dark bg-secondary bg-opacity-25">
                Sí, dependiendo del plan de suscripción, puedes agregar múltiples usuarios con distintos roles y permisos.
              </div>
            </div>
          </div>

          {/* Pregunta 7 */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question7">
                ¿Mis datos están seguros?
              </button>
            </h2>
            <div id="question7" className="accordion-collapse collapse" data-bs-parent="#qaAccordion">
              <div className="accordion-body text-dark bg-secondary bg-opacity-25">
                Sí, usamos cifrado de extremo a extremo y cumplimos con estándares de seguridad para proteger tu información.
              </div>
            </div>
          </div>

          {/* Pregunta 8 */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question8">
                ¿Puedo personalizar mi perfil?
              </button>
            </h2>
            <div id="question8" className="accordion-collapse collapse" data-bs-parent="#qaAccordion">
              <div className="accordion-body text-dark bg-secondary bg-opacity-25">
                Sí, puedes agregar tu foto, información profesional y ajustar la configuración de privacidad.
              </div>
            </div>
          </div>

          {/* Pregunta 9 */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question9">
                ¿Cómo puedo recibir notificaciones de nuevas citas?
              </button>
            </h2>
            <div id="question9" className="accordion-collapse collapse" data-bs-parent="#qaAccordion">
              <div className="accordion-body text-dark bg-secondary bg-opacity-25">
                Puedes activar las notificaciones por correo electrónico y en la plataforma desde la configuración de tu cuenta.
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  );
}

export default QA;
