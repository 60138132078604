import React, { useEffect, useState } from "react";
import api from "../../utils/api";
import { showLoader, hideLoader } from "../../utils/loader";
import { useAlerts } from "../../components/utils/alerts";

function Catalogos() {
  useEffect(() => {
    document.title = "PsiAdmin | Catálogos";
  }, []);


  const { showAlert } = useAlerts();

  const [activeTab, setActiveTab] = useState("configuracion");
  const [claveData, setClaveData] = useState({
    id_key: null,
    id_key_type: "0",
    value: "",
    description: '',
    active: false,
    claveSistema: false,
    claveGeneral: false
  });
  const [cambioTipoClaveSistema, setCambioTipoSistema] = useState(null);
  const [ modalClave, setModalClave] = useState(null);

  // Claves de ejemplo
  const [ clavesConfiguracion, setSystemKeys ] = useState([])
  const [ clavesSelects, setSelectSystem ] = useState([])
  const [ typeKeys, setTypeKeys] = useState([]);


  useEffect(() => {
    const modalElement = document.getElementById("claveModal");
    if (modalElement) {
      setModalClave(new bootstrap.Modal(modalElement, { backdrop: "static" }));
    }
  }, []);


 // RECUPERA LAS CLAVES AL CAMBIAR DE PESTAÑA
  useEffect(() => {
    if (activeTab !== null) {
      getKeys();
    }
  }, [activeTab]);

  // RECUPERACIÓN DE DATOS AL EDITAR CLAVE
  useEffect(() => {
    if (cambioTipoClaveSistema) {
      getTypeKeys();
    }    
  }, [claveData])
  

  const activaTab = (value) => {
    setActiveTab(value);
  };

  const openModal = () => { 
    setClaveData({
      id_key: null,
      id_key_type: "0",
      value: "",
      description: '',
      active: false,
      claveSistema: false,
      claveGeneral: false
    })
    modalClave.show();
  };

  const handleModelChange = (e, field) => {
    const { type, checked, value } = e.target;
    let cambioTipo = false;
    setClaveData(prevState => {
      const newState = {
        ...prevState,
        [field]: type === "checkbox" ? checked : value,
      };
  
      // Si se activa una clave, desactiva la otra
      if (field === "claveSistema" && checked) {
        newState["claveGeneral"] = false;
        setCambioTipoSistema(true);
      } else if (field === "claveGeneral" && checked) {
        newState["claveSistema"] = false;
        setCambioTipoSistema(true);
      } else {
        setCambioTipoSistema(false);
      }
      return newState;
    });
    
    
  };
  


  /**
   * 
   * CONSUMO DE APIS
   * 
   */
  function handleEdit(item) {
    showLoader();
    api.post('keys/get', {
      id_key: item.id_key
    }).then(res => {
      hideLoader();
      
      setClaveData(res.data);
      getTypeKeys(res.data);
    
    }).catch(err => {
      hideLoader();      
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  } 

  function getKeys() {
    showLoader();
    let sk = activeTab === 'configuracion' ? 1: 0;    

    if (sk === 1) {
      setSystemKeys([]);
    } else {
      setSelectSystem([]);
    }
    
    console.log("activeTab:", activeTab); // <--- clave
    console.log("sk:", sk);

    api.post('/keys/list', {
      system_key: sk,
      id_key_type: 0
    }).then(res => {
      hideLoader();

      if (res.data.length > 0) {
        if (sk === 1) {
          setSystemKeys(res.data);
        } else {
          setSelectSystem(res.data);
        }
      }      
    }).catch(err => {
      hideLoader();      
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  }

  function getTypeKeys(item) {
    showLoader(); 
    
    let obj;
    if (typeof item === 'object') {
      obj = item;
    } else {
      obj = claveData;
    }

    setTypeKeys([]);
    let sk = obj.claveSistema ? 1: 0;

    api.post('/keys/list-type-keys', {
      system_key: sk
    }).then(res => {
      hideLoader();

      setTypeKeys(res.data);     
      
      if (obj.id_key) {
        modalClave.show();
      }
    }).catch(err => {
      hideLoader();
      
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  }

  function handleDelete(item) {
    if (confirm(`¿Está seguro de eliminar la clave ${item.value}?`)) {
      showLoader();
      api.post('/keys/delete', {
        id_key: item.id_key
      }).then(res => {
        hideLoader();
        showAlert('success', 'Clave eliminada', 'La tabla de claves fue actualizada');
        getKeys();
      }).catch(err => {
        hideLoader();
        
        let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
        showAlert('danger', 'Error', msj );
      })
    }
  }

  function guardarClave() {
    let msj = '';
    
    if (!claveData.claveGeneral && !claveData.claveSistema) {
      msj += '<li>Elija entre clave de sistema  o clave general</li>';
    }
    if (Number(claveData.id_key_type) === 0) {
      msj += '<li>Elija un tipo de clave</li>';
    }
    if (claveData.value.trim() === '') {
      msj  += '<li>Ingrese el valor de la clave</li>';
    }

    if (msj !== '') {
      showAlert('danger', 'Verifique lo siguiente', msj);
      return;
    }

    var ruta = claveData.id_key === null ? '/keys/insert': '/keys/update';

    showLoader();
    api.post(ruta, claveData).then(res => {
      hideLoader();

      if (claveData.id_key === null) {
        showAlert('success', 'Clave creada', 'La tabla ha sido actualizada');
      } else {
        showAlert('success', 'Clave actualizada', 'La table ha sido actualizada');
      }
      getKeys();
      modalClave.hide();
    }).catch(err => {
      hideLoader();
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  }

  return (
    <section className="w-100 mt-4">
      <h2 className="mb-4 fw-bold">Catálogos del Sistema</h2>

      {/* Botón para agregar claves */}
      <div className="my-2">
        <button className="btn btn-primary" onClick={openModal}>
          Agregar Nueva Clave
        </button>
      </div>

      {/* Tabs para alternar entre los tipos de claves */}
      <div className="bg-light bg-opacity-75">
        <ul className="nav nav-tabs mb-3">
          <li className="nav-item">
            <button
              className={`bg-light nav-link ${activeTab === "configuracion" ? "active" : ""}`}
              onClick={() => { activaTab('configuracion') }}
            >
              Configuración del Sistema
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`bg-light nav-link ${activeTab === "selects" ? "active" : ""}`}
              onClick={() => {activaTab('selects')}}
            >
              Claves para Selects
            </button>
          </li>
        </ul>

        {/* Tabla de claves de configuración */}
        {activeTab === "configuracion" && (
          <div className="table-responsive">
            <table className="table table-striped text-center">
              <thead className="table-dark">
                <tr>
                  <th>Acción</th>
                  <th>ID</th>
                  <th>Tipo de Clave</th>
                  <th>Clave</th>
                  <th>Fecha Creación</th>
                  <th>Estatus</th>
                </tr>
              </thead>
              <tbody>
                {clavesConfiguracion.map((clave) => (
                  <tr key={clave.id}>
                    <td>
                        <button className="btn btn-warning btn-sm me-2" onClick={() => handleEdit(clave)}>Editar</button>
                        <button className="btn btn-danger btn-sm" onClick={() => handleDelete(clave)}>Eliminar</button>
                    </td>
                    <td>{clave.id}</td>
                    <td>{clave.key_type}</td>
                    <td>{clave.value}</td>
                    <td>{clave.created_at}</td>
                    <td>{clave.active ? 'Activo': 'Inactivo'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Tabla de claves para selects */}
        {activeTab === "selects" && (
          <div className="table-responsive">
            <table className="table table-striped text-center">
              <thead className="table-dark">
                <tr>
                  <th>Acción</th>
                  <th>ID</th>
                  <th>Tipo de Clave</th>
                  <th>Clave</th>
                  <th>Descripción</th>
                  <th>Estatus</th>
                </tr>
              </thead>
              <tbody>
                {clavesSelects.map((clave) => (
                  <tr key={clave.id_key}>
                    <td>
                        <button className="btn btn-warning btn-sm me-2" onClick={() => handleEdit(clave)}>Editar</button>
                        <button className="btn btn-danger btn-sm" onClick={() => handleDelete(clave)}>Eliminar</button>
                    </td>
                    <td>{clave.id_key}</td>
                    <td>{clave.key_type}</td>
                    <td>{clave.value}</td>
                    <td>{clave.created_at}</td>
                    <td>{clave.active ? 'Activo': 'Inactivo'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Modal para agregar nueva clave */}
      <div id="claveModal" className="modal fade"  data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header text-bg-dark">
              <h5 className="modal-title">{claveData.id_key ? 'Edición de clave': 'Nueva clave'}</h5>
              <button type="button" className="btn-close bg-white" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                    <div className="d-flex w-100 gap-2">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="claveSistema"
                                checked={claveData.claveSistema}
                                onChange={(e) => handleModelChange(e, "claveSistema")}
                            />
                            <label className="form-check-label text-dark" htmlFor="claveSistema">Clave de Sistema</label>
                        </div>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="claveGeneral"
                                checked={claveData.claveGeneral}
                                onChange={(e) => handleModelChange(e, "claveGeneral")}
                            />
                            <label className="form-check-label text-dark" htmlFor="claveGeneral">Clave general</label>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <label className="form-label text-dark">Tipo de Clave</label>
                    <select
                      className="form-select"
                      value={claveData.id_key_type}
                      onChange={(e) => handleModelChange(e, "id_key_type")}
                    >
                    <option value="0">Seleccione</option>
                    {typeKeys.map(i => {
                      return (
                        <option value={i.id_key_type}>{i.name}</option>
                      )
                    })}
                  </select>
                </div>
                <div className="col-12">
                  <label className="form-label text-dark">Valor</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ingrese el valor "
                    value={claveData.value}
                    onChange={(e) => handleModelChange(e, "value")}
                  />
                </div>
                <div className="col-12">
                  <label className="form-label text-dark">Descripción</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ingrese la descripción"
                    value={claveData.description}
                    onChange={(e) => handleModelChange(e, "description")}
                  />
                </div>
                <div className="col-12">
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input "
                            type="checkbox"
                            id="estatus"
                            checked={claveData.active}
                            onChange={(e) => handleModelChange(e, "active")}
                        />
                        <label className="form-check-label text-dark" htmlFor="estatus">Activo</label>
                    </div>
                </div>
                
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cerrar
              </button>
              <button type="button" className="btn btn-primary" onClick={guardarClave}>Guardar</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Catalogos;