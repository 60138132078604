import React, { useEffect, useState } from "react";
import '../../styles/index.css'
import { Link, useNavigate } from "react-router-dom";
import api from "../../utils/api";
import { showLoader, hideLoader } from "../../utils/loader";
import { useAlerts } from "../../components/utils/alerts";

function Login() {
  const { showAlert } = useAlerts();

  useEffect(() => {
    sessionStorage.clear();
    document.title = "PsiAdmin | Login Empleados";
  }, []);

  
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: '',
    password: ''
  });

  const handleChange = (e, field) => {
    setUser(prevState => ({
      ...prevState,
      [field]: e.target.value
    }));
  };

  const acceso = (evt) => {
    evt.preventDefault();
    let msj = '';

    if (user.email.trim() === '') {
      msj += '<li>Ingrese el usuario</li>';
    }
    if (user.password.trim() === '') {
      msj += '<li>Ingrese la contraseña</li>';
    }

    if (msj.trim() !== '') {
      showAlert('info', 'Verifique lo siguiente', msj);
      return;
    } else {
      showLoader();
      api.post(`/users/acceso`, {
        email: user.email.trim(),
        password: user.password.trim()
      }).then(res => {
        hideLoader()
        sessionStorage.setItem('user', JSON.stringify(res.data));
        navigate('/admin/dashboard')
      }).catch(err => {
        hideLoader()
        let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
        showAlert('danger', 'Error', msj );
      });      
    }
  }

  return (
    <div className="vh-100 login-container d-flex justify-content-center align-items-center">
      <div className="login-box w-100 p-4 shadow rounded text-center" style={{ maxWidth: "400px" }}>
         {/* <h2 className="text-center">Iniciar Sesión</h2> */}
         <h1 className="text-center">PsiAdmin</h1>
         <img src="logo.webp" style={{maxHeight: '120px'}} />
        <form className="login-box">
         
          <div className="mb-3">
            <label htmlFor="email" className="form-label text-start w-100">Correo Electrónico</label>
            <input type="email" className="form-control" onInput={(e) => handleChange(e, "email")} defaultValue={user.email} placeholder="Ingrese su correo" required />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label text-start w-100">Contraseña</label>
            <input type="password" className="form-control" onInput={(e) => handleChange(e, "password")} defaultValue={user.password} placeholder="Ingrese su contraseña" required  />
          </div>
          <button type="submit" className="btn btn-primary w-100" onClick={acceso}>Accesar</button>
        </form>
        <div className="mt-3 d-flex flex-column w-100 align-items-center h-100 gap-2">
          {/* <a href="#">¿Olvidaste tu contraseña?</a> */}
          <Link className="btn btn-outline-dark bi bi-house w-fit" to={'/'}>&nbsp;Página Principal</Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
