import React, { useEffect, useState } from "react";
import api from "../../utils/api";
import { showLoader, hideLoader } from "../../utils/loader";
import { useAlerts } from "../../components/utils/alerts";


function Contact() {
  const { showAlert } = useAlerts();  


  useEffect(() => {
    document.title = "PsiAdmin | Contáctanos";
  }, []);

  const [datos, setDatos] = useState({
    nombre: '',
    correo : '',
    mensaje: ''
  });

  const handleChange = (e, field) => {
    setDatos(prevState => ({
      ...prevState,
      [field]: e.target.value
    }));
  };

  const envia = (evt) => {
    evt.preventDefault;

    let msj = '';
    
    if (datos.nombre.trim() === '') {
      msj += '<li>Ingrese un nombre</li>';
    }
    if (datos.correo.trim() === '') {
      msj += '<li>Ingrese un correo</li>';
    }
    if (datos.mensaje.trim() === '') {
      msj += '<li>Ingrese el mensaje</li>';
    }

    if (msj.length > 0) {
      showAlert('danger', 'Verifique lo siguiente', msj);
    } else {
      showLoader();
      api.post('/contact/insert', datos).then(res => {
        hideLoader();
        showAlert('success', 'Tus datos fueron recibidos');
        
        setDatos({
          nombre: '',
          correo : '',  
          mensaje: ''
        })
      }).catch(err => {
        hideLoader();
        
        let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
        showAlert('danger', 'Error', msj );
      })
    }
  };
  return (
    <>
      {/* Contact Form Section */}
      <section className="container pb-5">
        <h2 className="text-center">Contáctanos</h2>
        <div className="col-12 col-md-6 col-xl-4 mx-auto">
          <div className="mb-3">
            <label htmlFor="name" className="form-label">Nombre</label>
            <input type="text" className="form-control" value={datos.nombre} onInput={(e) => handleChange(e, "nombre")} placeholder="Tu nombre" />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Correo Electrónico</label>
            <input type="email" className="form-control" value={datos.correo} onInput={(e) => handleChange(e, "correo")} placeholder="tu@email.com" />
          </div>
          <div className="mb-3">
            <label htmlFor="message" className="form-label">Mensaje</label>
            <textarea className="form-control" value={datos.mensaje} onInput={(e) => handleChange(e, "mensaje")} rows="4" placeholder="Escribe tu mensaje..." ></textarea>
          </div>
          <button type="button" className="btn btn-primary w-100" onClick={envia}>Enviar</button>
        </div>
      </section>
    </>
  );
}

export default Contact;
