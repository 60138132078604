import React, { useState, useEffect } from "react";
import { useAlerts } from "../../components/utils/alerts";
import api from "../../utils/api";
import { showLoader, hideLoader } from "../../utils/loader";

function PlansAdmin() {
  useEffect(() => {
    document.title = "PsiAdmin | Planes";
  }, []);


  const { showAlert } = useAlerts();

  const [plans, setPlans] = useState([]);
  const [planFilter, setPlanFilter] = useState({ nombre: "", precio: "" });
  const [currentPlan, setCurrentPlan] = useState({
    id_plan: null,
    name: '',
    price: 0,
    month_price: false,
    custom_price: false,
    active: true,
    features: [],
    order: 0
  });

  const [newFeature, setNewFeature] = useState('');

  const handleAddFeature = () => {
    if (newFeature.trim() !== '') {
      setCurrentPlan({
        ...currentPlan,
        features: [...currentPlan.features, { feature: newFeature.trim()}]
      });
      console.log(currentPlan.features);
      setNewFeature('');
    } else {
      showAlert('warning', 'Escriba la característica', 'El campo no puede estar vacío');
    }
  };

  const handleRemoveFeature = (index) => {
    const updated = [...currentPlan.features];
    updated.splice(index, 1);
    setCurrentPlan({ ...currentPlan, features: updated });
  };

  const [modal, setModal] = useState(null);


  /**
   *  
   * CARGA INICIAL 
   * 
   */
  useEffect(() => {
    getPlans();
    setModal(new window.bootstrap.Modal(document.getElementById("planModal")));
  }, []);

  const handleFilterChange = (e, field) => {
    const { value } = e.target;
    setPlanFilter(prevState => {
      const updatedFilter = { ...prevState, [field]: value };
      // searchPlans(updatedFilter);
      return updatedFilter;
    });
  };


  const handleDelete = (plan) => {
    if (confirm(`¿Está seguro de eliminar el plan ${plan.name}?`)) {
      showLoader();
      api.post('/plans/delete', {
        id_plan: plan.id_plan
      }).then(res => {
        hideLoader();
        getPlans();
        showAlert('success', 'Plan eliminado', 'La tabla de planes fue actualizada');
      }).catch(err => {
        hideLoader();
                
        let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
        showAlert('danger', 'Error', msj );
      })
    }
  };

  const handleEdit = (plan) => {
    setCurrentPlan({
      id_plan: null,
      name: '',
      amount: 0,
      month_price: false,
      custom_price: false,
      active: true,
      features: [],
      order: 0
    });

    showLoader();

    api.post('/plans/get', {
      id_plan:plan.id_plan
    }).then(res => {
      hideLoader();
      setCurrentPlan(res.data);
      modal.show();
    }).catch(err => {
      hideLoader();
        
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  };

  const handleAdd = () => {
    setCurrentPlan({
      id_plan: null,
      name: '',
      amount: 0,
      month_price: false,
      custom_price: false,
      active: true,
      features: [],
      order: 0
    });
    modal.show();
  };

  const handleSave = () => {
    let msj = '';
    if (currentPlan.name.trim() === '') {
      msj += '<li>Ingresa el nombre del plan</li>';
    }
    if (currentPlan.amount === 0 && currentPlan.month_price) {
      msj += '<li>Ingrese el precio del plan</li>';
    }
    if (currentPlan.features.length === 0) {
      msj += '<li>Ingrese las características del plan</li>';
    }
    if (!currentPlan.custom_price && !currentPlan.month_price) {
      msj += '<li>Elija una opción de precio mensual o precio personalizado</li>'
    }

    if (msj.length > 0) {
      showAlert('danger', 'Verifique lo siguiente', msj);
    } else {
      showLoader();

      let ruta = currentPlan.id_plan === null ? '/plans/insert': '/plans/update';

      api.post(ruta, currentPlan).then(res => {
        hideLoader();

        if (currentPlan.id_plan === null) {
          showAlert('success','Plan creado','La tabla de planes fue actualizada');
        } else {
          showAlert('success', 'Plan actualizado', 'La tabla de planes fue actualizada');
        }
        modal.hide();
        getPlans();
      }).catch(err => {
        hideLoader();
        
        let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
        showAlert('danger', 'Error', msj );
      })


    }
  };


  /***
   * 
   * APIS
   * 
   */
  function getPlans() {
    setPlans([]);
    showLoader();
    api.post('/plans/list', {
      name: planFilter.nombre,
      amount: planFilter.precio
    }).then(res => {
      hideLoader();

      if (res.data.length > 0) {
        setPlans(res.data);
      }
    }).catch(err => {
      hideLoader();
        
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  }

  return (
    <section className="w-100 mt-4">
      <h2 className="fw-bold">Administración de Planes</h2>
      <div className="row">
        <div className="col-12">
          <button className="btn btn-primary my-2" onClick={handleAdd}>Agregar Plan</button>
        </div>
        <div className="col-12 col-md-4 col-xl-3">
          <label>Nombre</label>
          <input type="search" className="form-control" placeholder="Buscar por nombre" onChange={(e) => handleFilterChange(e, "nombre")} />
        </div>
        <div className="col-12 col-md-4 col-xl-3">
          <label>Precio</label>
          <input type="search" className="form-control" placeholder="Buscar por precio" onChange={(e) => handleFilterChange(e, "precio")} />
        </div>
        <div className="col-12 col-md-4 col-xl-3">
            <div className="d-flex align-items-end h-100">
                <button className="btn btn-primary h-auto" onClick={() => { getPlans() }}>
                    <span className="bi bi-search">Buscar</span>
                </button>
            </div>
        </div>

        <div className="col-12 table-responsive my-2">          
          <table className="table table-bordered table-hover">
            <thead className="table-dark">
              <tr>
                <th>Acciones</th>
                <th>ID</th>
                <th>Nombre</th>
                <th>Orden</th>
                <th>Precio</th>
                <th>Características</th>               
              </tr>
            </thead>
            <tbody>
              {plans.map((plan) => (
                <tr key={plan.id_plan}>
                  <td>
                    <button className="btn btn-warning btn-sm me-2" onClick={() => handleEdit(plan)}>Editar</button>
                    <button className="btn btn-danger btn-sm" onClick={() => handleDelete(plan)}>Eliminar</button>
                  </td>
                  <td>{plan.id_plan}</td>
                  <td>{plan.name}</td>
                  <td>{plan.order}</td>
                  <td>{plan.amount}</td>
                  <td>
                    <ul>
                      {plan?.features?.map((feature, index) => (
                        <li key={index}>{feature.feature}</li>
                      ))}
                    </ul>
                  </td>
                 
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal para Agregar/Editar Plan */}
      <div className="modal fade" id="planModal" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header text-bg-dark">
              <h5 className="modal-title">{currentPlan.id_plan ? "Editar Plan" : "Agregar Plan"}</h5>
              <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <label className="text-dark">Nombre</label>
              <input type="text" className="form-control" placeholder="Nombre" value={currentPlan.name} onChange={(e) => setCurrentPlan({ ...currentPlan, name: e.target.value })} />
              
              <div className="d-flex flex-wrap">
                <div className="col-4 pe-2">
                  <label className="text-dark mt-3">Precio</label>
                  <input type="number" min={0} max={99999} onClick={(e) => {e.target.select()}} step={.5} className="form-control" placeholder="Precio" value={currentPlan.amount} onChange={(e) => setCurrentPlan({ ...currentPlan, amount: e.target.value })} />
                </div>

                <div className="col-4 pe-2">
                  <label className="text-dark mt-3">Orden</label>
                  <input type="number" min={0} max={99} step={1}  onClick={(e) => {e.target.select()}} className="form-control" placeholder="Precio" value={currentPlan.order} onChange={(e) => setCurrentPlan({ ...currentPlan, order: e.target.value })} />
                </div>

                <div className="col-4 ps-2">
                  <div className="d-flex h-100 align-items-end">
                    <div className="form-check form-switch ">
                      <input className="form-check-input" type="checkbox" id="activePlan" checked={currentPlan.active} onChange={(e) => setCurrentPlan({ ...currentPlan, active: e.target.checked })} />
                      <label className="form-check-label text-dark" htmlFor="activePlan">&nbsp;Activo</label>
                    </div> 
                  </div>                  
                </div>     

                <div className="col-6">
                  <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="monthPrice" checked={currentPlan.month_price} onChange={(e) => setCurrentPlan({ ...currentPlan, month_price: e.target.checked })} />
                    <label className="form-check-label text-dark" htmlFor="monthPrice">Precio mensual</label>
                  </div>
                </div>   
                <div className="col-6">
                  <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="customPrice" checked={currentPlan.custom_price} onChange={(e) => setCurrentPlan({ ...currentPlan, custom_price: e.target.checked })} />
                    <label className="form-check-label text-dark" htmlFor="customPrice">Precio personalizado</label>
                  </div>
                </div>                   
              </div>
              
              <label className="text-dark mt-3">Agregar característica</label>
              <div className="d-flex gap-2">
                <input type="text" className="form-control" placeholder="Nueva característica" value={newFeature} onChange={(e) => setNewFeature(e.target.value)} />
                <button type="button" className="btn btn-outline-primary" onClick={handleAddFeature}>Agregar</button>
              </div>

              <ul className="list-group mt-2">
                {
                  currentPlan.features && currentPlan.features.length > 0 ?
                      currentPlan.features?.map((feature, index) => (
                      <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                        {feature?.feature ? feature.feature: feature}
                        <button type="button" className="btn btn-sm btn-danger" onClick={() => handleRemoveFeature(index)}>X</button>
                      </li>
                    )): null
                }
              </ul>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
              <button type="button" className="btn btn-primary" onClick={handleSave}>Guardar</button>
            </div>
          </div>
        </div>
      </div>


    </section>
  );
}

export default PlansAdmin;