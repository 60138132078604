import React, { useRef, useEffect, useState } from "react";
import api from "../../utils/api";
import { showLoader, hideLoader } from "../../utils/loader";
import { useAlerts } from "../utils/alerts";

function ModalAgendarCita({ paciente, onAgendar, id_psychologist }) {
  const { showAlert} = useAlerts();

  const [ precioCitas, setPreciosCitas ] = useState([]);
  const modalRef = useRef(null);
  const [cita, setCita] = useState({
    id_appointment: null,
    id_patient: null,
    id_psychologist: id_psychologist,
    date: '',
    time: '',
    notes: '',
    is_with_companions: false,
    id_charge_type: '0',
    created_at: null,
    updated_at: null,
    id_status: '15'
  });

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current = new bootstrap.Modal(document.getElementById("modalAgendarCita"), { backdrop: "static" });
    }
  }, []);



  useEffect(() => {
    if (id_psychologist != null && typeof id_psychologist !== 'undefined') {
      getChargesTypes();
    }
  }, [id_psychologist])

  

  const handleChange = (e, field) => {
    setCita({ ...cita, [field]: e.target.type === 'checkbox' ? e.target.checked: e.target.value });
  };

 

  const handleConfirmarCita = () => {
    let msj = '';
    if (cita.date.trim() === '') {
      msj += '<li>Ingrese la fecha</li>';
    }
    if (cita.time.trim() === '') {
      msj += '<li>Ingrese la hora de la cita</li>';
    }
    if (Number(cita.id_charge_type) === 0) {
      msj += '<li>Elija un precio de consulta estimada</li>'
    }

    if (msj.length > 0) {
      showAlert('danger', 'Verifique lo siguiente', msj);
      return;
    }

    cita.id_patient = paciente?.id_patient;
    cita.id_psychologist = id_psychologist;
    
    showLoader();
    api.post('/appointments/insert', cita).then(res => {
      hideLoader();
 
      // showAlert('success', 'Cita realizada', 'Paciente' + paciente.name);
      
      setCita({
        id_patient: null,
        id_appointment: null,
        id_psychologist: null,
        date: '',
        time: '',
        notes: '',
        is_with_companions: false,
        id_charge_type: '0',
        created_at: null,
        updated_at: null,
        id_status: '15'
      });
      onAgendar(cita);
      modalRef.current.hide();
    }).catch(err => {
      hideLoader();      
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })    
  };


  function getChargesTypes() {
    showLoader();
    api.post('/appointments/charge-types-list', {
      id_psychologist: id_psychologist
    }).then(res => {
      hideLoader();
      setPreciosCitas(res.data);
    }).catch(err => {
      hideLoader();      
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    }) 
  }

  return (
    <div className="modal fade" id="modalAgendarCita" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-hidden="true" ref={modalRef}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-dark">Agendar Cita</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div className="modal-body">
            <span className="text-dark">Paciente: {paciente?.name}</span>
            <input type="date" className="form-control mb-2" value={cita.date} onChange={(e) => handleChange(e, "date")} />

            <input type="time" className="form-control mb-2" value={cita.time} onChange={(e) => handleChange(e, "time")} />

            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="checkAcomp" checked={cita.is_with_companions} 
                value={cita.date} onChange={(e) => handleChange(e, "is_with_companions")} />
              <label className="form-check-label text-dark" htmlFor="checkAcomp">¿Vendrá con acompañantes?</label>
            </div>

            <label className="text-dark">Notas</label>
            <textarea rows={4} value={cita.notes} className="form-control" onChange={(e) => handleChange(e, "notes")}></textarea>

            <label className="text-dark">Precio consulta estimada</label>
            <select className="form-select" value={cita.id_charge_type} onChange={(e) => handleChange(e, "id_charge_type")}>
              <option value={'0'}>Seleccione</option>
              {
                precioCitas.map((i, index) => {
                  return (
                    <option key={index} value={i.id_charge_type}>{i.name}, precio {i.price}</option>
                  )
                })
              }
            </select>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            <button type="button" className="btn btn-primary" onClick={handleConfirmarCita}>Confirmar Cita</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalAgendarCita;
