import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import api from "../../utils/api";
import { useAlerts } from "../../components/utils/alerts";
import { showLoader, hideLoader } from "../../utils/loader";
import { DateTime } from 'luxon';

function Calendar() {
  const [citaSeleccionada, setCitaSeleccionada] = useState(null);
  const [citaStatus, setCiteStatus] = useState([]);
  const [statusCita, setStatusCita] = useState('0');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  const [citasFiltradas, setCitasFiltradas] = useState([]);


  const handleEventClick = ({ event }) => {
    const datos = event.extendedProps;
    
    
    const dt = DateTime.fromISO(event.startStr);

    // Obtener por separado
    const fecha = dt.toFormat("yyyy-MM-dd"); // "2025-03-05"
    const hora = dt.toFormat("HH:mm"); 

    setCitaSeleccionada({
      id: event.id,
      title: event.title,
      start: fecha + ' ' + hora,
      ...datos
    });

    setDate(fecha);
    setTime(hora);
    setStatusCita(datos.id_status);
    const modal = new bootstrap.Modal(document.getElementById('modalCita'));
    modal.show();
  };


  const [userData, setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
  const { showAlert } = useAlerts();

  useEffect(() => {
    document.title = "PsiAdmin | Calendario";
  }, []);


  const [citas, setCitas] = useState([]);
  
  const [pacienteFiltro, setPacienteFiltro] = useState("");
  const [pacientesUnicos, setPacientesUnicos] = useState([]);
  const [filtrosSeleccionados, setFiltrosSeleccionados] = useState([]);

  useEffect(() => {
    const uniquePacientes = [...new Set(citas.map(cita => cita.paciente))];
    // setPacientesUnicos(uniquePacientes);
    getKeys(4);
    // cargarCitasPorRango();
  }, [citas]);

  const handleFiltroChange = (e) => {
    setPacienteFiltro(e.target.value);
  };

  const handleAgregarFiltro = () => {
    if (pacienteFiltro && !filtrosSeleccionados.includes(pacienteFiltro)) {
      setFiltrosSeleccionados([...filtrosSeleccionados, pacienteFiltro]);
      setPacienteFiltro("");
    }
  };

  const handleEliminarFiltro = (paciente) => {
    setFiltrosSeleccionados(filtrosSeleccionados.filter(filtro => filtro !== paciente));
  };


  // const loadAppointments = async (fetchInfo, successCallback, failureCallback) => {
  //   showLoader();
  //   try {
  //     const res = await api.post('/appointments/cites-fullcalendar', {
  //       id_psychologist: userData.id_psychologist,
  //       start: fetchInfo.startStr,
  //       end: fetchInfo.endStr
  //     });
  
  //     hideLoader();
  //     successCallback(res.data); // fullcalendar pinta los eventos
  
  //   } catch (err) {
  //     hideLoader();
  //     let msj = typeof err.response?.data === 'string' ? err.response.data : err.message;
  //     showAlert('danger', 'Error cargando eventos', msj);
  //     failureCallback(err); // evita que fullcalendar vuelva a intentar
  //   }
  // };

  function getKeys(id_key_type) {
    showLoader();    

    api.post('/keys/list', {
      system_key: 0,
      id_key_type: id_key_type
    }).then(res => {
      hideLoader();

      switch (id_key_type) {
        case 4:
          setCiteStatus(res.data);
          break;
      }
    }).catch(err => {
      hideLoader();      
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  }
  
  const guardarCambioEstatus = async () => {
    try {
      const { id, id_status } = citaSeleccionada;
      
      let msj = '';
      if (date?.trim() === '' || date === 0) {
        msj += '<li>Ingrese la fecha de la cita</li>';
      }
      if (time?.trim() === '' || time === 0) {
        msj += '<li>Ingrese la hora de la cita</li>';
      }
      if (Number(statusCita) === 0) {
        msj += '<li>Seleccione un nuevo estatus</li>';
        return;
      }
      
      if (msj !== '') {
        showAlert('danger', 'Verifique lo siguiente', msj);
        return;
      }

      showLoader();
      const res = await api.post('/appointments/update', {
        id_appointment: id,
        id_status: statusCita,
        tipo: 1,
        date: date,
        time: time
      });
  
      showAlert('success', 'Cita actualizada', 'El estatus fue actualizado correctamente');
      const modal = bootstrap.Modal.getInstance(document.getElementById('modalCita'));
      modal.hide();
      
      const { startStr, endStr } = getInicioYFinMes();
      cargarCitasPorRango(startStr, endStr);

      // Refrescar eventos si es necesario
      // calendarRef.current.getApi().refetchEvents?.();
    } catch (err) {
      hideLoader();
      let msj = typeof err.response?.data === 'string' ? err.response.data : err.message;
      showAlert('danger', 'Error al actualizar', msj);
    }
  };
  

  const handleDatesChange = (info) => {
    const { startStr, endStr } = info;
    cargarCitasPorRango(startStr, endStr);
  };

  const cargarCitasPorRango = async (start, end) => {
    showLoader();
    try {
      const res = await api.post('/appointments/cites-fullcalendar', {
        id_psychologist: userData.id_psychologist,
        start: start,
        end: end
      });
      setCitasFiltradas(res.data);
    } catch (err) {
      const msj = typeof err.response?.data === 'string' ? err.response.data : err.message;
      showAlert('danger', 'Error al cargar citas', msj);
    } finally {
      hideLoader();
    }
  };

  const getInicioYFinMes = (date = new Date()) => {
    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    
    // Convertir a formato ISO yyyy-mm-dd
    const startStr = start.toISOString().split('T')[0];
    const endStr = end.toISOString().split('T')[0];
  
    return { startStr, endStr };
  };


  return (
    <div className="p-4 text-white" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", borderRadius: "10px" }}>
      <h2 className="mb-4">Agenda de Citas</h2>
      
      {/* Filtro por Paciente */}
      {/* <div className="mb-3 d-flex align-items-center gap-2">
        <div className="flex-grow-1">
          <label className="form-label">Filtrar por Paciente:</label>
          <input 
            type="text" 
            className="form-control" 
            placeholder="Escriba el nombre del paciente..." 
            value={pacienteFiltro} 
            onChange={handleFiltroChange} 
            list="pacientesList"
          />
          <datalist id="pacientesList">
            {pacientesUnicos.map((paciente, index) => (
              <option key={index} value={paciente} />
            ))}
          </datalist>
        </div>
        <button className="btn btn-primary mt-4" onClick={handleAgregarFiltro}>Filtrar</button>
      </div> */}
      
      {/* Lista de Filtros Seleccionados */}
      <div className="mb-3">
        {filtrosSeleccionados.map((paciente, index) => (
          <span key={index} className="badge bg-secondary me-2 p-2">
            {paciente} <button type="button" className="btn-close btn-close-white ms-1" onClick={() => handleEliminarFiltro(paciente)}></button>
          </span>
        ))}
      </div>
      
      {/* Calendario */}
      <div className="bg-white p-2 rounded-3 text-dark w-100 h-100">
        <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin, listPlugin, timeGridPlugin]}
            initialView="dayGridMonth"            
            locale="es"
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,listDay'
            }}
            events={citasFiltradas}
            datesSet={handleDatesChange}
            // events={loadAppointments}
            eventClick={(e) => {handleEventClick(e)}}
        />
      </div>

      <div className="modal fade" id="modalCita" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content text-dark">
            <div className="modal-header">
              <h5 className="modal-title">Detalle de la Cita</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body">
              <p><strong>Paciente:</strong> {citaSeleccionada?.title}</p>
              <p><strong>Fecha:</strong> {citaSeleccionada?.start}</p>
              <p><strong>Notas:</strong> {citaSeleccionada?.notes || 'Sin nota'}</p>

              <label className="form-label mt-3">Fecha</label>
              <input type="date" className="form-control mb-2" value={date} onChange={(e) => setDate(e.target.value)} />

              <label className="form-label mt-3"> Hora</label>
              <input type="time" className="form-control mb-2" value={time} onChange={(e) => setTime(e.target.value)} />

              <label className="form-label mt-3"> Estatus</label>
              <select
                className="form-select"
                value={statusCita}
                onChange={(e) => setStatusCita(Number(e.target.value))}
              >
                <option value="0">Seleccione</option>
                {
                  citaStatus.map((i, index) => {
                    return (
                      <option key={index} value={i.id_key}>{i.value}</option>
                    )
                  })  
                }
              </select>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cerrar
              </button>
              <button type="button" className="btn btn-primary" onClick={guardarCambioEstatus}>
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calendar;
