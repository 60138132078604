import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PublicLayout from "./layouts/PublicLayout";
import Home from "./pages/public/Home";
import QA from "./pages/public/QA";
import Contact from "./pages/public/Contact";
import Planes from "./pages/public/Plans";
import Login from "./pages/public/Login";
import AdminLayout from "./layouts/AdminLayout";

import Dashboard from "./pages/admin/Dashboard";
import Plans from "./pages/admin/Plans";
import Users from "./pages/admin/Users";
import Profiles from "./pages/admin/Profiles";
import TrailWatch from "./pages/admin/TrailWatch";
import Catalogos from "./pages/admin/Catalogs";
import ContactList from "./pages/admin/Contact";


import {AlertsProvider} from "./components/utils/alerts";

import NotFound from "./pages/NotFound";

import CustomerlLayout from "./layouts/CustomerLayout";
import DashboardCustomer from "./pages/customer/Dashboard";
import AccountSuscription from "./pages/customer/AccountsSuscription";
import Patients from "./pages/customer/Patients";
import Calendar from "./pages/customer/Calendar";
import Cobros from "./pages/customer/Cobros";
import LoginCustomer from "./pages/public/LoginCustomer";

import RequireAuthCustomer from "./requires/RequireAuth";


function App() {
  return (
    <AlertsProvider>
      <Router>
        <Routes>
          <Route element={<PublicLayout />}>

            <Route path="/" element={<Home />} />
            <Route path="/qa" element={<QA />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/products" element={<Planes />} />   

          </Route>


          <Route element={
            <RequireAuthCustomer>
              <CustomerlLayout />
            </RequireAuthCustomer>
          }>
            <Route path="/customer/dashboard" element={<DashboardCustomer />} />
            <Route path="/customer/account" element={<AccountSuscription />} />
            <Route path="/customer/patients" element={<Patients />} />
            <Route path="/customer/calendar" element={<Calendar />} />
            <Route path="/customer/incomes" element={<Cobros />} />
          </Route>



          <Route element={
            <RequireAuthCustomer>
              <AdminLayout />
            </RequireAuthCustomer>
          }>
              <Route element={<AdminLayout />}>
                <Route index path="/admin/dashboard" element={<Dashboard />} />
                <Route path="/admin/contact" element={<ContactList />} />

                {/* Configuración */}
                <Route path="/admin/configuracion/plans" element={<Plans />} />
                <Route path="/admin/configuracion/catalogs" element={<Catalogos />} />

                {/* Seguridad */}
                <Route path="/admin/seguridad/users" element={<Users />} />         
                <Route path="/admin/seguridad/profiles" element={<Profiles />} />   
                <Route path="/admin/seguridad/records" element={<TrailWatch />} />   

              </Route>
          </Route>

          

        
          <Route path="/clientes" element={<LoginCustomer />} />
          <Route path="/empleados" element={<Login />} />

          <Route path="*" element={<NotFound />}></Route>
        </Routes>
       
      </Router>
    </AlertsProvider>    
  );
}


export default App;
