import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { hideLoader } from "../utils/loader";

function PublicLayout() {
  useEffect(() => {
    hideLoader();
  }, []);

  return (
    <main style={{ fontFamily: "'Poppins', sans-serif"}}>
      {/* Navbar Section */}
      <nav className="navbar navbar-dark bg-public py-2 py-md-1 navbar-expand-lg fixed-top shadow-sm">
        <div className="container">
          <a className="navbar-brand fw-bold" href="/">PsiAdmin</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto d-flex d-md-auto flex-column flex-md-row align-items-center">
              <li className="nav-item"><a className="nav-link text-light" href="/products">Registrarse</a></li>
              <li className="nav-item"><a className="nav-link text-light" href="/products">Planes</a></li>
              <li className="nav-item dropdown">
                <a className="nav-link text-light dropdown-toggle" href="#" id="loginDropdown" role="button" data-bs-toggle="dropdown" data-bs-target="#loginOptions" aria-expanded="false">Iniciar sesión</a>
                <div className="dropdown-menu position-absolute bg-white shadow p-1 rounded" id="loginOptions">
                  <a className="dropdown-item text-dark py-2 px-3" href="/empleados">Empleados</a>
                  <a className="dropdown-item text-dark py-2 px-3" href="/clientes">Clientes</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      
      {/* Main Content */}
      <section style={{ fontFamily: "'Nunito', sans-serif"}}  className="container-fluid mt-2 pt-5 main-content public-layout">
        <Outlet />
      </section>

      {/* Footer Section */}
      <footer style={{ fontFamily: "'Poppins', sans-serif"}} className="bg-public  text-white text-center py-3 fixed-bottom">
        <div className="container">
          <div className="row">
            <div className="col"><a href="/products" className="text-white">Planes</a></div>
            <div className="col"><a href="/qa" className="text-white">Q&A</a></div>
            <div className="col"><a href="/contact" className="text-white">Contáctanos</a></div>
          </div>
        </div>
      </footer>
    </main>
  );
}

export default PublicLayout;
