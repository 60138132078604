import { useEffect } from "react";
import { Link } from "react-router-dom";

function NotFound() {
    useEffect(() => {
        document.title = "PsiAdmin | Páginas no encontrada";
      }, []);


    return (
        <section className="container my-2 text-center">
            <h4 className="w-100 h-100 text-center card card-body">Ups!! Página no encontrada</h4>
            <Link className="text-center btn btn-outline-primary bi bi-house-fill w-fit" to={'/'}>Ir al Inicio</Link>
        </section>
    )
}

export default NotFound;