import React, { useState } from "react";
import { Outlet, Link } from "react-router-dom";

function CustomerLayout() {
  const [menuOpen, setMenuOpen] = useState(false);




  const handleMenuClick = () => {
    if (window.innerWidth < 768) {
      setMenuOpen(false);
    }
  };

  const salir = () => {
    sessionStorage.clear();
    document.location.assign('/');  
  }



  return (
    <div className="container-fluid">
      <div className="row">
        <button className="btn btn-dark d-md-none p-3" onClick={() => setMenuOpen(!menuOpen)}>
          ☰ Menú
        </button>

        <nav
          className={`col-md-3 m-0 col-lg-2 d-md-block text-bg-dark sidebar py-3 ${menuOpen ? "d-block" : "d-none d-md-block"}`}
          style={{ position: "sticky", top: 0, height: "100vh", overflowY: "auto" }}
        >
          <h4 className="fw-bold">PsiAdmin</h4>
          <ul className="nav flex-column portal">
            <li className="nav-item">
              <Link className="nav-link text-white" to="/customer/dashboard" onClick={handleMenuClick}>Dashboard</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/customer/account" onClick={handleMenuClick}>Cuenta y Suscripción</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/customer/patients" onClick={handleMenuClick}>Pacientes</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/customer/calendar" onClick={handleMenuClick}>Agenda y Citas</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/customer/incomes" onClick={handleMenuClick}>Cobros</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/" onClick={salir}>Cerrar Sesión</Link>
            </li>
          </ul>
        </nav>

        <main className="col-md-9 my-0 mx-0 col-lg-10 px-md-3 py-4 text-black customer-layout">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default CustomerLayout;
