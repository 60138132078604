export function validatePasswordStrength(password) {
    const errors = [];
  
    if (!/.{8,}/.test(password)) {
      errors.push("Debe tener al menos 8 caracteres");
    }
    if (!/[A-Z]/.test(password)) {
      errors.push("Debe contener al menos una letra mayúscula");
    }
    if (!/[a-z]/.test(password)) {
      errors.push("Debe contener al menos una letra minúscula");
    }
    if (!/\d/.test(password)) {
      errors.push("Debe contener al menos un número");
    }
    if (!/[^A-Za-z0-9]/.test(password)) {
      errors.push("Debe contener al menos un carácter especial");
    }
  
    return errors.length === 0
      ? ""
      : errors.map(msg => `<li>${msg}</li>`).join("");
  }