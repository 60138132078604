import { useEffect, useState } from "react";
import axios from "axios";
import api from "../../utils/api";
import { showLoader, hideLoader } from "../../utils/loader";
import { useAlerts } from "../../components/utils/alerts";

function ContactList() {
    const [messages, setMessages] = useState([]); 
    const { showAlert } = useAlerts();  

    useEffect(() => {
        document.title = "PsiAdmin / Mensajes de Contacto";

        getMessages();
    }, []);

    const getMessages = async () => {
       api.post('/contact/list').then(res => {
        hideLoader();

        if (res.data.length > 0) {
            setMessages(res.data);
        }

       }).catch(err => {
            hideLoader();
        
            let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
            showAlert('danger', 'Error', msj );
       })
    };    

    return (
        <div className="container py-5">
        <h2 className="mb-4 text-center">Mensajes Recibidos</h2>
        <div className="table-responsive">
            <table className="table table-bordered table-striped">
            <thead className="table-dark">
                <tr>
                <th>Nombre</th>
                <th>Correo</th>
                <th>Mensaje</th>
                <th>Fecha</th>
                </tr>
            </thead>
            <tbody>
                {messages.length > 0 ? (
                messages.map((msg) => (
                    <tr key={msg.id_contact}>
                    <td>{msg.nombre}</td>
                    <td>{msg.correo}</td>
                    <td>{msg.mensaje}</td>
                    <td>{new Date(msg.fecha_envio).toLocaleString()}</td>
                    </tr>
                ))
                ) : (
                <tr>
                    <td colSpan="4" className="text-center">No hay mensajes registrados.</td>
                </tr>
                )}
            </tbody>
            </table>
        </div>
        </div>
    );
}

export default ContactList;
