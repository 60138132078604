import React, { useState, useEffect, useRef } from "react";
import api from "../../utils/api";
import { showLoader, hideLoader } from "../../utils/loader";
import { useAlerts } from "../../components/utils/alerts";

function Cobros() {
  useEffect(() => {
    document.title = "PsiAdmin | Cobros";

    getKeys(2);
    getPayments();
    getChargesTypes();
    getKpis();
  }, []);
   
  const { showAlert } = useAlerts();

  const [ precioCitas, setPreciosCitas ] = useState([]);


  const [userData, setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
  const [id_psychologist, setIdPpsychologist] = useState(userData.id_psychologist)  
  const [paciente, setPaciente] = useState({
    id_patient: '',
    name: ''
  })

  const [cobros, setCobros] = useState([]);

  const [kpis, setKpis] = useState({
    totalMesActual: 0,
    totalMesAnterior: 0,
    totalYTD: 0
  })

  
  const [paymentTypes, setPaymentTypes] = useState([])
  const [pacienteFiltro, setPacienteFiltro] = useState("");
  const [filtrosSeleccionados, setFiltrosSeleccionados] = useState([]);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [pacientesUnicos, setPacientesUnicos] = useState([]);
  const [nuevoCobro, setNuevoCobro] = useState({ 
    id_charge: 0,
    amount: '',
    charge_date: '',
    id_charge_type: 0,
    id_payment_method: 0,
    notes: '',
    id_patient: 0,
    id_psychologist: id_psychologist
  });
  const modalRef = useRef(null);

  useEffect(() => {
    modalRef.current = new bootstrap.Modal(document.getElementById("modalNuevoCobro"), { backdrop: "static" });
  }, [cobros]);
  
  const handleFiltroChange = (e) => {
    setPacienteFiltro(e.target.value);

    if (e.target.value.trim().length > 2) {
      showLoader();
      api.post('/patients/list', {
        id_psychologist: id_psychologist,
        name: e.target.value
      }).then(res => {
        hideLoader();
        setPacientesUnicos(res.data);
      }).catch(err => {
        hideLoader();
        let msj = typeof err.response?.data === 'string' ? err.response.data : err.message;
        showAlert('danger', 'Error al actualizar', msj);
      })
    }    
  };

  const getKpis = (e) => {
    showLoader();
    api.post('/charges/kpis', {
      id_psychologist: id_psychologist
    }).then(res => {
      hideLoader();

      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      setKpis({
        totalMesActual:formatter.format( res.data.MesActual),
        totalMesAnterior: formatter.format(res.data.MesAnterior),
        totalYTD: formatter.format(res.data.TotalAnio)
      })
    }).catch(err => {
      hideLoader();
      let msj = typeof err.response?.data === 'string' ? err.response.data : err.message;
      showAlert('danger', 'Error al actualizar', msj);
    }) 
  };


  const handleGuardarCobro = () => {
    let msj = '';
    if (Number(nuevoCobro.amount) === 0) {
      msj += '<li>Ingrese el monto del cobro</li>';
    }
    if (nuevoCobro.charge_date.trim() === '') {
     msj += '<li>Ingrese la fecha del cobro</li>'; 
    }
    if (Number(nuevoCobro.id_charge_type) === 0) {
      msj += '<li>Elja el tipo de cobro</li>';
    }
    if (Number(nuevoCobro.id_payment_method) === 0) {
      msj += '<li>Elija el método de pago</li>';
    }
    if (Number(nuevoCobro.id_patient) === 0) {
      msj += '<li>Busque primero el paciente</li>';
    }

    if (msj.trim() !== '') {
      showAlert('danger', 'Verifique lo siguiente', msj);
      return;
    }



    showLoader();
    api.post('/charges/insert', nuevoCobro).then(res => {
      hideLoader();

      showAlert('success', 'Cobro realizado', 'Se realizó un cobro al paciente ' +  paciente.name);
      getPayments();
      modalRef.current.hide()
    }).catch(err => {
      hideLoader();      
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  };

  const handleNuevoCobroChange = (evt, name) => {
    setNuevoCobro(prev => {
      return {
        ...prev,
        [name]: evt.target.value
      }
    })
  }

  function getPayments() {
    showLoader();
    api.post('/charges/list', {
      id_psychologist: id_psychologist
    }).then(res => {
      hideLoader();

      if (res.data.length > 0) {
        setCobros(res.data);
      }
   
    }).catch(err => {
      hideLoader();      
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  }

  function getChargesTypes() {
    showLoader();
    api.post('/appointments/charge-types-list', {
      id_psychologist: id_psychologist
    }).then(res => {
      hideLoader();
      setPreciosCitas(res.data);
    }).catch(err => {
      hideLoader();      
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    }) 
  }

  const agregarCobro = (cobro) => {
    let id_patient, name;
    
    var arr = pacienteFiltro.split('-');
    id_patient = arr[0];
    name = arr[1];

    setNuevoCobro({ 
      id_charge: 0,
      amount: 0,
      charge_date: '',
      id_charge_type: 0,
      id_payment_method: 0,
      notes: '',
      id_patient: id_patient,
      name: name,
      id_psychologist: id_psychologist
    });

    setPaciente({
      name: name,
      id_patient: id_patient
    })
    modalRef.current.show()
  }

  function getKeys(id_key_type) {
    showLoader();    

    api.post('/keys/list', {
      system_key: 0,
      id_key_type: id_key_type
    }).then(res => {
      hideLoader();

      switch (id_key_type) {
        case 2:
          setPaymentTypes(res.data);
          break;
      }
    }).catch(err => {
      hideLoader();      
      let msj = typeof err.response.data === 'string' ? err.response.data: err.message;
      showAlert('danger', 'Error', msj );
    })
  }




  return (
    <div className="p-4 text-white" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", borderRadius: "10px" }}>
      <h2 className="mb-4">Gestión de Cobros</h2>
      
            {/* KPIs */}
      <div className="row mb-3">
        <div className="col-6 col-md-4">
          <div className="card bg-success text-white text-center p-3">
            <h5>Mes Actual</h5>
            <h3>{kpis.totalMesActual}</h3>
          </div>
        </div>
        <div className="col-6 col-md-4">
          <div className="card bg-primary text-white text-center p-3">
            <h5>Mes Anterior</h5>
            <h3>{kpis.totalMesAnterior}</h3>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="card bg-warning text-dark text-center p-3">
            <h5>Total Cobrado Año en Curso</h5>
            <h3>{kpis.totalYTD}</h3>
          </div>
        </div>
      </div>



      {/* Filtros */}
      <div className="row mb-3">
        <div className="col-12 col-md-4">
            <label className="form-label">Buscar paciente a cobrar:</label>

            <div className="d-flex">
                <input 
                    type="text" 
                    className="form-control" 
                    placeholder="Ingrese nombre del paciente a buscar" 
                    value={pacienteFiltro} 
                    onChange={handleFiltroChange} 
                    list="pacientesList"
                />
                <datalist id="pacientesList">
                    {
                      pacientesUnicos && pacientesUnicos.length > 0 ? 
                        pacientesUnicos?.map((paciente, index) => (
                      <option key={index} value={paciente.id_patient + '-' + paciente.name}>
                        {paciente.id_patient + ' - ' + paciente.name}
                      </option>
                      )): null
                  }
                </datalist>
                <button className="btn btn-primary text-nowrap" onClick={agregarCobro}>Agregar cobro</button>
            </div>
        </div>
        {/* <div className="col-6 col-md-3">
          <label className="form-label">Fecha Inicio:</label>
          <input type="date" className="form-control" value={fechaInicio} onChange={(e) => setFechaInicio(e.target.value)} />
        </div>
        <div className="col-6 col-md-3">
          <label className="form-label">Fecha Fin:</label>
          <input type="date" className="form-control" value={fechaFin} onChange={(e) => setFechaFin(e.target.value)} />
        </div>
        <div className="col-12 col-md">
            <div className="d-flex h-100 align-items-end">
                <button className="btn btn-secondary">Buscar</button>
            </div>
        </div> */}
      </div>

      {/* Lista de Filtros Seleccionados */}
      <div className="mb-3">
        {filtrosSeleccionados.map((paciente, index) => (
          <span key={index} className="badge btn btn-sm btn-dark me-2 p-1">
            {paciente} <button type="button" className="btn-close btn-close-white ms-1" onClick={() => handleEliminarFiltro(paciente)}></button>
          </span>
        ))}
      </div>
      
      {/* Tabla de Cobros */}
      <div className="table-responsive w-100">
        <table className="table table-striped">
          <thead>
            <tr>
              {/* <th>Nuevo cobro</th> */}
              <th>Paciente</th>
              <th>Fecha</th>
              <th>Monto</th>
              <th>Tipo de Cargo</th>
              <th>Método de Pago</th>
            </tr>
          </thead>
          <tbody>
            {cobros.map((cobro, index) => (
              <tr key={index}>
                {/* <th>
                  <button className="btn btn-info text-white mb-3" onClick={agregarCobro(cobro)}>Nuevo Cobro</button>
                </th> */}
                <td>{cobro.patient_name}</td>
                <td>{cobro.date_charge}</td>
                <td>{cobro.amount_currency}</td>
                <td>{cobro.charge_type}</td>
                <td>{cobro.payment_method}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal de Nuevo Cobro */}
      <div className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false"  id="modalNuevoCobro" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark">Nuevo Cobro</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body">
              <span className="text-dark fw-bold">Paciente: {paciente.name}</span>
              <br />
              <br />
              <label className="text-dark">Monto a cobrar</label>
              <input type="number" className="form-control mb-2" placeholder="Monto" value={nuevoCobro.amount} onChange={(e) => handleNuevoCobroChange(e, "amount")} />

              <label className="text-dark">Fecha del cobro</label>
              <input type="date" className="form-control mb-2" value={nuevoCobro.charge_date} onChange={(e) => handleNuevoCobroChange(e, "charge_date")} />


              <label className="text-dark">Tipo de cargo</label>
              <select className="form-select mb-2" value={nuevoCobro.id_charge_type} onChange={(e) => handleNuevoCobroChange(e, "id_charge_type")}>
                <option value="0">Seleccione Tipo de Pago</option>
                {precioCitas.map((i, index) => {
                  return (
                    <option key={index} value={i.id_charge_type}>{i.name}, (Precio default) {i.price}</option>
                  )
                })}
              </select>
                
              <label className="text-dark">Método de Pago</label>
              <select className="form-select mb-2" value={nuevoCobro.id_payment_method} onChange={(e) => handleNuevoCobroChange(e, "id_payment_method")}>
                <option value="0">Seleccione Tipo de Pago</option>
                {paymentTypes.map((i, index) => {
                  return (
                    <option key={index} value={i.id_key}>{i.value}</option>
                  )
                })}
              </select>

              <label className="text-dark">Notas</label>
              <textarea className="form-control" rows={3} value={nuevoCobro.notes} onChange={(e) => handleNuevoCobroChange(e, "notes")}></textarea>
            </div>
            <div className="modal-footer">
              <button className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
              <button className="btn btn-primary" onClick={handleGuardarCobro}>Guardar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cobros;